import React, { ReactElement } from 'react';
import { handleOnEnterKeyPress, printDateTP, truncateLabel } from '../../utility/Api';
import CardCover from './CardCover';
import { TrainingPill } from '@model/ProductClass';
import { LangMap } from '@model/CoursesClass';
import WishlistIcon from '@components/WishlistIcon';
import TagBadge from '@components/UI/TagBadge';
import clsx from '@utility/clsx';
import TagMultipleBadges from '@components/UI/TagMultipleBadges';

export type CardPillDisplayProps = {
  lang: LangMap;
  product: TrainingPill;
  brandName: string;
  modelId: string;
  modelName: string;
  badgeLabels: (string[] | string)[];
  newPicksForYou: boolean;
  isComingSoon: boolean;
  image: string;
  fallbackImage: string;
  isWishlist: boolean;
  isLoadingWishlist: boolean;
  isLoadingWishlistLocal: boolean;
  toggleWishlist: (e?) => void;
  openProductModal: () => void;
  tabIndex: number;
  showAsMobile: boolean;
  shipmentDate?: string;
  isNovelty?: boolean;
};

const CardMediumPill = (props: CardPillDisplayProps): ReactElement => {
  // const [showMessageComingSoon, setShowMessageComingSoon] = useState(false);

  const handleClickCard = () => {

    // if (props.isComingSoon) {
    //   setShowMessageComingSoon(true);
    // }
    if (!props.isComingSoon) {
      props.openProductModal();
    }
  };

  const handleKeyDown = event => {
    handleOnEnterKeyPress(event, handleClickCard, true);
  };

  const cardContent = (
    <>
      <CardCover
        variant="training-pill-medium"
        image={props.image}
        courseFullName={props.modelId}
        fallbackImage={props.fallbackImage}
        dontCrop={!props.image}
        hideShadow
        centerSpinnerVertical={true}
      />
      <div className="card-v3__cover">
        <div className="width-100">
          {props.newPicksForYou && (
            <section
              className="card-v3-medium-pill__top-banner"
              aria-label={props.lang.TP_NEW_PICKS_FOR_YOU}
            >
              <div className="chip normal">
                <span className="chip__label flex justify-center align-center">
                  <span>{props.lang.TP_NEW_PICKS_FOR_YOU}</span>
                </span>
              </div>
            </section>
          )}
          {/* {props.isComingSoon &&
            <div className="card-v3-medium-pill__top-banner coming-soon">
              <span>{props.lang.COMING_SOON_LABEL}</span>
            </div>
          } */}

          {/* {(props.isComingSoon && showMessageComingSoon) &&
            <AnimatePresence>
              <AnimationOpacity duration={0.2}>
                <div className="card-v3-medium-pill__bottom-banner coming-soon">
                  <span>{props.lang.TP_AVAILABLE_FEW_DAYS}</span>
                </div>
              </AnimationOpacity>
            </AnimatePresence>
          } */}

          {props.isWishlist !== undefined && (
            <WishlistIcon
              className="card-v3__wishlist card-v3-medium-pill__wishlist--heart"
              handleClickCondition={false}
              isWishlist={props.isWishlist}
              isLoadingWishlistLocal={props.isLoadingWishlistLocal}
              isNotYetAvailable={false}
              enableWishlist={true}
              toggleWishlist={props.toggleWishlist}
            />
          )}
        </div>
      </div>
    </>
  );

  return (
    <div
      className={clsx('card-v3 card-v3-medium-pill', { 'is-novelty': props.isNovelty })}
      data-id={props.modelId}
      onClick={handleClickCard}
      onKeyDown={handleKeyDown}
      aria-label={props.lang.SHOW_OVERVIEW_ITEM?.replace(
        '{name}',
        truncateLabel(props.modelName || props.modelId, 50)
      )}
      tabIndex={props.tabIndex}
    >
      <div className={'card-v3__wrapper'}>{cardContent}</div>

      <section
        className={`card-v3-medium-pill__info ${props.isComingSoon ? 'disabled' : ''}`}
        aria-label={props.modelName || props.modelId}
      >
        <div>
          {props.brandName && (
            <div className={'card-v3__title'} title={props.brandName}>
              <span>{truncateLabel(props.brandName, 50)}</span>
            </div>
          )}
          {props.modelId && (
            <div className={'card-v3__title-model'} title={props.modelId}>
              <span>{truncateLabel(props.modelId, 50)}</span>
            </div>
          )}
          {props.modelName && (
            <div className={'card-v3__title-model-name'} title={props.modelName}>
              <span>{truncateLabel(props.modelName, 50)}</span>
            </div>
          )}

          {props.shipmentDate && (
            <div className="card-v3-medium-pill__shipped-on">
              <span>
                {props.lang.TP_SHIPPED_ON?.replace(
                  '{date}',
                  printDateTP(props.shipmentDate, props.lang)
                )}
              </span>
            </div>
          )}

          {props.badgeLabels?.length > 0 && (
            <div className="card-v3-medium-pill__badges">
              {props.badgeLabels.map(
                badge => Array.isArray(badge) ? (
                  <TagMultipleBadges variant="grey" key={badge[0]} badges={badge} />
                ) : (
                  <TagBadge variant="grey" key={badge} label={badge} />
                )
              )}
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default CardMediumPill;
