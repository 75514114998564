import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '@utility/Api';

class InitialStateProps {
  //usersToAssign : UserCompletion[] = [];
  usersToAssign = [];
  isLoadingGetUsersToAssign = false;
  assignmentsCourses = [];
  retakesCourses = []
  isLoadingAssignmentsAndRetakesCourseIds = false;
  isLoadedAssignmentsAndRetakesCourseIds = null;
  isLoadingPostUsersToAssign = false;
}

const initialState: InitialStateProps = new InitialStateProps();

const getUsersToAssignRequest = state => {
  return updateObject(state, {
    isLoadingGetUsersToAssign: true
  })
}

const getUsersToAssignFailed = state => {
  return updateObject(state, {
    isLoadingGetUsersToAssign: true
  })
}

const getUsersToAssignCompleted = (state,action) => {
  return updateObject(state, {
    isLoadingGetUsersToAssign: true,
    usersToAssign : action.payload
  })
}

const postUsersToAssignRequest = state => {
  return updateObject(state, {
    isLoadingPostUsersToAssign: true
  })
}

const postUsersToAssignFailed = state => {
  return updateObject(state, {
    isLoadingPostUsersToAssign: false
  })
}

const postUsersToAssignCompleted = (state,action) => {
  return updateObject(state, {
    isLoadingPostUsersToAssign: false,
  })
}

const getAssignmentsAndRetakesCourseIdsRequested = state => {
  return updateObject(state, {
    isLoadingAssignmentsAndRetakesCourseIds: true
  })
}

const getAssignmentsAndRetakesCourseIdsSuccess = (state,action) => {
  return updateObject(state, {
    isLoadingAssignmentsAndRetakesCourseIds: false,
    assignmentsCourses : action.assignmentsCourses,
    retakesCourses : action.retakesCourses,
    isLoadedAssignmentsAndRetakesCourseIds: true
  })
}

const getAssignmentsAndRetakesCourseIdsFailed = (state) => {
  return updateObject(state, {
    isLoadingAssignmentsAndRetakesCourseIds: false,
    isLoadedAssignmentsAndRetakesCourseIds: true
  })
}


const reducer = (state = initialState, action): InitialStateProps => {
    switch (action.type) {
        case actionTypes.GET_USERS_TO_ASSIGN_REQUESTED : return getUsersToAssignRequest(state);
        case actionTypes.GET_USERS_TO_ASSIGN_COMPLETED : return getUsersToAssignCompleted(state,action);
        case actionTypes.GET_USERS_TO_ASSIGN_FAILED: return getUsersToAssignFailed(state);
        case actionTypes.GET_ASSIGNMENTS_AND_RETAKES_COURSE_IDS_REQUESTED: return getAssignmentsAndRetakesCourseIdsRequested(state);
        case actionTypes.GET_ASSIGNMENTS_AND_RETAKES_COURSE_IDS_SUCCESS: return getAssignmentsAndRetakesCourseIdsSuccess(state,action);
        case actionTypes.GET_ASSIGNMENTS_AND_RETAKES_COURSE_IDS_FAILED: return getAssignmentsAndRetakesCourseIdsFailed(state);
        case actionTypes.POST_USERS_TO_ASSIGN_REQUESTED : return postUsersToAssignRequest(state);
        case actionTypes.POST_USERS_TO_ASSIGN_COMPLETED : return postUsersToAssignCompleted(state,action);
        case actionTypes.POST_USERS_TO_ASSIGN_FAILED: return postUsersToAssignFailed(state);
        default:
            return state;
    }
};
export default reducer;



