import { useState, useEffect } from 'react';
import { Course, CoursesMap } from '@model/CoursesClass';
import {
  isCompletedCourse,
  isCourseVisible,
  isLearningPath,
  isMandatory,
  isOnboardingRelatedMandatory,
  isToDo,
  orderToDos,
} from '@utility/Api';
import { getAllLpCoursesId } from '@utility/LPUtility';
import { useSelector } from '@hooks/redux';
import { sortCourses } from '@utility/sortUtility';
import { SORTBY } from '@utility/const';
import { useDispatch } from 'react-redux';
import { setShowOnboardingRMCarousel } from '@redux-actions/';

const useGetOnboardingRMCourses = (switchOff = false): Course[] => {
  const coursesMap: CoursesMap = useSelector(state => state.course.coursesMap);
  const [onboardingRMCourses, setOnboardingRMCourses] = useState<Course[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (switchOff) {
      //prevent useless calculations
      return;
    }

    let onboardingRMCoursesTemp: Course[] = [];

    if (coursesMap) {
      for (let course of Object.values(coursesMap)) {
        if (isOnboardingRelatedMandatory(course) && isCourseVisible(course)) {
            onboardingRMCoursesTemp.push(course);
        }
      }
    }

    //sort courses: use To Do sort + show completed courses as last
    onboardingRMCoursesTemp = sortCourses(orderToDos(onboardingRMCoursesTemp), SORTBY.DONE_FOR_LAST);

    setOnboardingRMCourses(onboardingRMCoursesTemp);
    
    const shouldShowCarousel = onboardingRMCoursesTemp?.length > 0 && onboardingRMCoursesTemp?.some(course => course && Object.keys(course).length > 0);
    if (shouldShowCarousel){
      dispatch(setShowOnboardingRMCarousel(true));
    } else {
      dispatch(setShowOnboardingRMCarousel(false));
    }
    
  }, [coursesMap, switchOff]);

  return onboardingRMCourses;
};
export default useGetOnboardingRMCourses;
