import { Course, CoursesMap } from '@model/CoursesClass';
import { UserProfile } from '@model/User';
import { getUserRelevantSessions } from '@redux-actions/';
import {
  //getTodayMidnight,
  getCurrentDateTime,
  isAttendedSession,
  isCompletedCourse,
  isCourseVisible,
  isLivestreamCourse,
  isNewLivestream,
  isSessionInTheFuture,
  isVCInTheFuture,
  isVirtualClassroom,
  orderUpcomingEvents,
} from '@utility/Api';
import { SORTBY } from '@utility/const';
import { isLivestreamNotYetFinished } from '@utility/LivestreamUtility';
import { compareCourses } from '@utility/sortUtility';
import {
  getAssociatedSessionFromLivestreamInfo,
  getAssociatedSessionFromVCSession,
} from '@utility/upcomingEventsUtility';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/store';


export const checkUpcomingOrPastEvent = (course, userProfile, filterBooked?: boolean) => {
  let courseSessions = [];
  if (course && isVirtualClassroom(course)) {
    //check if the VC has session in the future or today
    if (isVCInTheFuture(course, true, null, true)) {
      let isVCCompleted = isCompletedCourse(course);

      for (let session of course.courseSessions) {
        //show session if it's today or in the future
        if (isSessionInTheFuture(session, true)) {
          const courseWithAssociatedSession = cloneDeep(course);
          courseWithAssociatedSession.associatedSession = getAssociatedSessionFromVCSession(
            session,
            course,
            userProfile
          );
          if (!filterBooked || courseWithAssociatedSession.associatedSession.isBooked) {
            if (isVCCompleted) {
              //if VC completed --> show just the completed session
              if (courseWithAssociatedSession.associatedSession.isAttendedSession) {
                courseWithAssociatedSession.isPastEvent = false;
                courseSessions.push(courseWithAssociatedSession);
                //return courseWithAssociatedSession;
              }
            } else {
              courseWithAssociatedSession.isPastEvent = false;
              courseSessions.push(courseWithAssociatedSession);
              //return courseWithAssociatedSession;
            }
          }
        }
        //  else {
        //   const courseWithAssociatedSession = cloneDeep(course);
        //   courseWithAssociatedSession.associatedSession = getAssociatedSessionFromVCSession(
        //     session,
        //     course,
        //     userProfile
        //   );
        //   courseWithAssociatedSession.isPastEvent = true;
        //   courseSessions.push(courseWithAssociatedSession);
        // }
      }
      //if at least one session in future --> return false --> UPCOMING_EVENTS
      if (courseSessions.length > 0) {
        return courseSessions;
      }

      //if VC completed and has other sessions in the future --> ignore the future sessions and put it in the past events
      course.isPastEvent = true;

      return [course];
      // return true;  //return true --> PAST_EVENTS
    } else {
      //search for attended sessions
      const userRelevantAttendedSessions = course.userRelevantSessions?.filter(isAttendedSession);
      if (userRelevantAttendedSessions && userRelevantAttendedSessions.length > 0) {
        //if at least one session is attended (should be maximum one) --> ignore others
        for (const userRelevantAttendedSession of userRelevantAttendedSessions) {
          const session = course?.courseSessions?.find(
            a => a.id === userRelevantAttendedSession.id
          );
          const courseWithAssociatedSession = cloneDeep(course);
          courseWithAssociatedSession.associatedSession = getAssociatedSessionFromVCSession(
            session,
            course,
            userProfile
          );
          courseWithAssociatedSession.isPastEvent = true;
          if (courseWithAssociatedSession.associatedSession?.timeStart !== undefined) {
            courseSessions.push(courseWithAssociatedSession);
          }
        }
      } else {
        courseSessions.push(checkLastSession(course, userProfile));
      }
      return courseSessions;
      /*  for (let session of course.courseSessions) { 
 
         const courseWithAssociatedSession = cloneDeep(course);
         courseWithAssociatedSession.associatedSession = getAssociatedSessionFromVCSession(session, course, userProfile);
 
         courseWithAssociatedSession.isPastEvent = true;
         courseSessions.push(courseWithAssociatedSession);
       }
       return courseSessions; */
      //return true; //return true --> PAST_EVENTS
    }
  } else if (course && isLivestreamCourse(course)) {
    //check if the livestream is not already finished or it's finished today
    if (isLivestreamNotYetFinished(course, userProfile, getCurrentDateTime())) {
      const courseWithAssociatedSession = cloneDeep(course);
      courseWithAssociatedSession.associatedSession = getAssociatedSessionFromLivestreamInfo(
        course.liveInfo?.[0],
        course,
        userProfile
      );
      courseWithAssociatedSession.isPastEvent = false;
      courseSessions.push(courseWithAssociatedSession);
      //return [courseWithAssociatedSession];
    } else {
      const courseWithAssociatedSession = cloneDeep(course);
      courseWithAssociatedSession.associatedSession = getAssociatedSessionFromLivestreamInfo(
        course.liveInfo?.[0],
        course,
        userProfile
      );
      courseWithAssociatedSession.isPastEvent = true;
      courseSessions.push(courseWithAssociatedSession);
      //return [courseWithAssociatedSession];
    }
    return courseSessions;
  } else {
    return [course];
  }
};

export const checkLastSession = (course, userProfile) => {
  course.courseSessions = course?.courseSessions?.sort((a, b) => {
    const aDate = a?.minStartDate;
    const bDate = b?.minStartDate;

    if (aDate !== bDate) {
      return bDate?.localeCompare(aDate);
    } else {
      return compareCourses(a, b, SORTBY.TITLE_AZ);
    }
  });

  const courseWithAssociatedSession = cloneDeep(course);
  courseWithAssociatedSession.associatedSession = getAssociatedSessionFromVCSession(
    course.courseSessions?.[0],
    course,
    userProfile
  );
  courseWithAssociatedSession.isPastEvent = true;
  if (courseWithAssociatedSession.associatedSession?.timeStart !== undefined) {
    //courseSessions.push(courseWithAssociatedSession);
    return courseWithAssociatedSession;
  }
};

const useUpcomingEvents = (filterBooked: boolean = false): [Course[], Course[]] => {
  const userProfile: UserProfile = useSelector((state: RootState) => state.user.userProfile);
  const coursesMap: CoursesMap = useSelector((state: RootState) => state.course.coursesMap);

  const [upcomingCourses, setUpcomingCourses] = useState<Course[]>([]);
  const [pastCourses, setPastCourses] = useState<Course[]>([]);

  useEffect(() => {
    let upcomingCoursesTemp: Course[] = [];
    let pastCoursesTemp: Course[] = [];

    if (coursesMap) {
      for (let course of Object.values(coursesMap)) {
        if (isCourseVisible(course)) {
          //course = checkUpcomingOrPastEvent(course, userProfile, filterBooked);
          /*  if (course?.isPastEvent) {
             pastCoursesTemp.push(course);
           } else if (course?.isPastEvent != undefined) {
             upcomingCoursesTemp.push(course);
           } */
            let courseSessions;
          if(!isNewLivestream(course)){
           courseSessions = cloneDeep(
            checkUpcomingOrPastEvent(course, userProfile, filterBooked)
          );}
          else{
            courseSessions = cloneDeep(
              checkUpcomingOrPastEvent(course, userProfile, false)
            );
          }

          for (let courseSession of courseSessions) {
            // console.log("CourseSSS", courseSession);
            if (courseSession?.isPastEvent) {
              pastCoursesTemp.push(courseSession);
            } else if (courseSession?.isPastEvent != undefined) {
              upcomingCoursesTemp.push(courseSession);
            }
          }
        }
      }
    }

    //sort upcoming events by session
    upcomingCoursesTemp = upcomingCoursesTemp.sort((a, b) => {
      const aDate = a.associatedSession.timeStart;
      const bDate = b.associatedSession.timeStart;

      if (aDate !== bDate) {
        return aDate.localeCompare(bDate);
      } else {
        return compareCourses(a, b, SORTBY.TITLE_AZ);
      }
    });

    //sort past events from the newest to the oldest
    pastCoursesTemp = orderUpcomingEvents(pastCoursesTemp, coursesMap, true);
    //console.log('UPCOMING_EVENTS', upcomingCoursesTemp);
    setUpcomingCourses(upcomingCoursesTemp);
    //console.log('PAST_EVENTS', pastCoursesTemp);
    setPastCourses(pastCoursesTemp);

    // console.log('upcoming events', { upcomingCoursesTemp, pastCoursesTemp })
  }, [coursesMap, userProfile]);

  return [upcomingCourses, pastCourses];
};

export default useUpcomingEvents;
