export type BaseProduct = {
  callToActionEnabled: boolean;
  url?: string;
  related: any[];
  path: string;
  folderId: number;
  creationDate: string;
  lastModified: string;
  keywords: string;
  htmlDescription: string;
  htmlTitle: string;
  contentId: number;
  title: string;
  language: string;
  restType: string;
  contentType: string;
  locale: string;
};

export type ExtendedFields = {
  Shape: string;
  Collection: string;
  Brand: ProductCMS[];
  BrandName: string;
  Rxable: boolean;
  ModelString: string;
  ProductImageLinks: string[];
  // Moco: Moco[],
  Model: ProductCMS[];
  Polarized: boolean;
  SizeAvailable: string;
  BrandString: string;
  OpticalTips?: ProductCloseUp[];
  AdvBrandVideo?: ProductCloseUp[];
  AdvCampaignImage?: ProductNOADVCampaignImages[];
  MoodBoard?: ProductCloseUp[];
  sustainability?: string;
  sustainabilityDescription?: string;
};

// export type Moco = {
//   Code: string,
//   FrameColor: string,
//   LensColor: string,
//   Image: string,
//   colorCode: string;
//   // launchDateCode: Date;
//   specialProjectCollection: string;
//   specialProjectSponsor: string;
//   frameColor: string;
//   lensColor: string;
//   // lensBase: string;
//   CollectionShortName: string;
//   IsCollectionNew: string;
// }

export type Picture = {
  mimeType: string;
  copyright: string;
  caption: string;
  disableCropping: boolean;
  alt: string;
  data: string;
};

export type ProductCMS = BaseProduct & {
  Category?: string;
  Theme?: string;
  code?: string;
  modelName?: string;
  gender?: string;
  materialFront?: string;
  targetUrl?: string;
  teaserText: string;
  detailText: string;
  pictures: Picture[];
  frontalImage?: string;
  teaserTitle: string;
  extDisplayedDate: Date;
  subjectTaxonomy: any[];
  locationTaxonomy: string[];
  segment: string;
  pillsFieldsCms?: ExtendedFields;
  title: string;
  language: string;
  externalReference?: string;
  "lookup-value"?: string;
  "lookup-externalReference"?: string;
  taxonomyVector?: string;
  value?: string;
  callToActionCustomText?: string;
  bestSeller: boolean;
  advertising: boolean;
  isNew: boolean;
  newPicksForYou: boolean;
  wishlist: boolean;
  mocoFromSearch?: MocoFromSearch[];
  release: string;
  releaseLongName?: string;
  keySellingPoints?: KeySellingPoints;
  ageRange?: string;
  marketingTheme?: string;
  marketingStory?: string;
  exclusiveCollection?: string;
  templeMaterial?: string;
  lensMaterial?: string;
  lensWidth?: string;
  lensBase?: string;
  exactSize?: string;
  frameShape?: string;
  geoFit?: string;
  faceShape?: string;
  progressiveFriendly?: boolean;
  asianFriendly?: boolean;
  adv?: boolean;
  polarized?: boolean;
  marketingTheme2: string;
  size: string;
  frameMaterialFacet: string;
};

export type MocoFromSearch = {
  brand: string,
  brandName: string,
  code: string,
  collectionLongName: string,
  collectionShortName: string,
  colorCode: string,
  frameColor: string,
  lastCollection: boolean,
  launchDateCode: number,
  lensBase: string,
  lensColor: string,
  marketingTheme: string,
  model: string,
  modelName: string,
  order: string,
  release: string,
  specialProjectCollection: string,
  specialProjectSponsor: string,
  urlImage: string,
  colorAdd: boolean,
  overlapImage?: string,
  exclusiveCollection: string,
  localCollection?: string,
  sustainabilityDescription: string,
  channelSpecific?: string,
  polarized: string;
  blueLightProtection: string;
  photochromic: string;
  templeColor: string;
  frontColor: string;
  marketingTheme2: string;
  main_release: string;
  rxAble?: boolean;
  gender?: string;
  brandCampaign?: string;
}

export type ProductPLP = BaseProduct & {
  mimeType: string;
  alt: string;
  copyright: string;
  caption: string;
  disableCropping: boolean;
  data: string;
  teaserTitle: string;
  url: string;
  extendedFields: ExtendedFields;
  segment: string;
  extDisplayedDate: Date;
  subjectTaxonomy: string[];
  locationTaxonomy: any[];
  title: string;
  language: string;
};

export type ProductPLPExtendedFields = {
  dimension: string;
};

export type ProductNOADVCampaignImages = BaseProduct & {
  mimeType: string;
  alt: string;
  copyright: string;
  caption: string;
  disableCropping: boolean;
  timeLine: any[];
  data: string;
  playerSettings: PlayerSettings;
  teaserTitle: string;
  pictures: string[];
  callToActionCustomText: string;
  url: string;
  extendedFields: {
    dimension: string;
  };
  segment: string;
  extDisplayedDate: Date;
  subjectTaxonomy: string[];
  locationTaxonomy: any[];
  title: string;
  language: string;
};

export type PlayerSettings = {
  muted: boolean;
  autoplay: boolean;
  hideControls: boolean;
  loop: boolean;
};

export interface KeySellingPoints {
  KeySellingPoint1: string,
  KeySellingPoint2: string,
  KeySellingPoint3: string
};

export type ExtendedFieldsCloseUp = {
  Brand?: ProductCMS[];
  Model?: ProductCMS[];
  Event?: ProductCMS[];
  Release?: ProductCMS[];
  dimension?: string;
  BrandName?: string;
  Rxable?: boolean;
  ModelString?: string;
  ProductImageLinks?: string[];
  Polarized?: boolean;
  SizeAvailable?: string;
  BrandString?: string;
  Content1Image: ProductCloseUpImage[];
  Content2Image: ProductCloseUpImage[];
  Content3Image: ProductCloseUpImage[];
  Closeup1Image: ProductCloseUpImage[];
  Closeup2Image: ProductCloseUpImage[];
  Closeup3Image: ProductCloseUpImage[];
  KeySellingPoint?: ProductCMS[]
};

export type ProductCloseUp = BaseProduct & {
  MoodBoardTitle1: string;
  MoodBoardTitle: string;
  Closeup1Text: string;
  Closeup2Text: string;
  Closeup3Text: string;
  Content1Text: string;
  Content2Text: string;
  Content3Text: string;
  targetUrl: string;
  teaserText: string;
  detailText: string;
  pictures: Picture[];
  teaserTitle: string;
  extDisplayedDate: Date;
  subjectTaxonomy: string[];
  locationTaxonomy: any[];
  segment: string;
  url: string;
  path: string;
  data?: string;
  extendedFields: ExtendedFieldsCloseUp;
  title: string;
  language: string;
  timeLine?: any[];
  Frontal1?: string;
  Frontal2?: string;
  Frontal3?: string;
  Temple1?: string;
  Temple2?: string;
  Temple3?: string;
  Others1?: string;
  Others2?: string;
  Others3?: string;
  playerSettings: PlayerSettings;
};
export type ProductCloseUpImage = {
  mimeType: string;
  alt: string;
  copyright: string;
  caption: string;
  disableCropping: boolean;
  data: string;
  teaserTitle: string;
  callToActionEnabled: boolean;
  related: any[];
  contentType: string;
  locale: string;
  url: string;
  path: string;
  folderId: number;
  creationDate: Date;
  lastModified: Date;
  extendedFields: {
    dimension?: string;
  };
  segment: string;
  extDisplayedDate: Date;
  subjectTaxonomy: any[];
  locationTaxonomy: any[];
  keywords: string;
  htmlDescription: string;
  htmlTitle: string;
  contentId: number;
  title: string;
  language: string;
  restType: string;
};


export class TrainingPill {
  image: string;              //url a immagine
  fallbackImage?: string;      //set by FE
  brandName: string;          //Ray-ban
  brandString: string;        //brand id
  model: string;              //0RB2186
  modelName: string;          //STATE STREET
  bestSeller: boolean;      //show badge Best seller
  advertising: boolean;     //show badge Advertising
  rxAble: boolean;          //show badge Rx-able
  new: boolean;             //show badge New
  newPicksForYou: boolean;  //show badge New picks for you
  wishlist: boolean;          //pill added to wishlist or not
  category: string;           //product category (sun, optical, ...)
  orderDate?: string;       //@todo show badge shipped on dd/mm/yyyy
  isNovelty?: boolean;      //true if is novelty
  positionInCarousel?: number;
  title?: string; //position in New to your store carousel,
  brandCampaign?: string[]; //COLLECTION WAVE 3
}

export class SingleWeekSummary {
  week: number;
  totalCount: number;
  facetCount: {
    newModels: number,
    newPicksForYou: number
  }
}

export class FormattedSingleWeekSummary {
  id: string;
  items: {
    newPicksForYou: number;
    newModels: number;
  }
}

export class RequestGetProducts {
  filterPills: ResponseGetProductsItems; //oggetto per raccogliere filtri e sorting selezionati dall'utente per training pills
  filterNovelties: ResponseGetProductsItems;//oggetto per raccogliere filtri e sorting selezionati dall'utente per novelties
}

export type ResponseGetFilters = {
  elementsNum: number,			                  	  //numero delle collections che rispettano i filtri applicati
  items: ResponseGetFilterItems,                        //oggetto per raccogliere tutti i possibili filtri da mostrare
}

export class ResponseGetFilterItems {
  [key: string]: Array<ResponseGetProductsFilter>,
}

export interface ResponseGetProducts extends ResponseGetFilters {
  elementsNum: number,			                  	  //numero delle training pills che rispettano i filtri applicati
  items: ResponseGetProductsItems,                        //oggetto per raccogliere tutti i possibili filtri e sorting da mostrare
}

export class ResponseGetProductsItems extends ResponseGetFilterItems {
  sortings: Array<ResponseGetProductsFilter>;		      //sezione sortings che conterrà i sortings
  months?: Array<ResponseGetProductsFilter>;	  	      //sezione months che conterrà i filtri months
  collections?: Array<ResponseGetProductsFilter>;
  characteristics?: Array<ResponseGetProductsFilter>;
  brands?: Array<ResponseGetProductsFilter>;
  categories?: Array<ResponseGetProductsFilter>;
  status?: Array<ResponseGetProductsFilter>;
  orderDate?: [startDate: ResponseGetProductsFilter, endDate: ResponseGetProductsFilter] | [];
  orderDateType?: number;
  faceShape?: Array<ResponseGetProductsFilter>;
  frontColor?: Array<ResponseGetProductsFilter>;
  material?: Array<ResponseGetProductsFilter>;
  treatment?: Array<ResponseGetProductsFilter>;
  launchDate?: Array<ResponseGetProductsFilter>;
}

export type ResponseGetProductsFilter = {
  id: string,				    //id del filtro
  title?: string,
  disabled: boolean,		//booleano se il filtro è abilitato o disabilitato
  checked: boolean,		  //booleano se il filtro è selezionato dall'utente
  order?: number,			  //al momento usato solo per collections
}

export const TP_COLLECTION = {
  NEW: 'NEW',
  CARRYOVER: 'CARRYOVER',
}

export const TP_CHARACTERISTICS = {
  ADVERTISING: 'advertising',
  BESTSELLER: 'bestSeller',
  RXABLE: 'rxable',
  NEW_PICK_FOR_YOU: 'newPicksForYou',
}

export const TP_CATEGORY = {
  SUN: 'Sun',
  OPTICAL: 'Optical',
  GOGGLES: 'Goggles',
  CLIPON: 'Clip-On',
  HALF_EYES: 'Half Eyes',
  WEARABLE: 'Wearable',
  SUN_PRESCRIPTION: 'Sun Prescription Lens',
  PRESCRIPTION: 'Optical Prescription Lens',
  HELMETS: 'Helmets',
  ELECTONICS: 'Electronics Optical',
  FOOTWEAR: 'AFA Footwear',
  AFA_ACCESSORIES: 'AFA Accessories',
  APPAREL: 'AFA Apparel',
  INSTRUMENT: 'B - Instrument',
  INSTRUMENT_PARTS: 'D - Instrument Spare Parts',
  SNOW: 'Snow Goggles',
  SNOW_ACCESSORIES: 'Snow Goggles Accessories',
  ACCESSORIES: 'Accessories',
  MX_GOGGLES: 'MX Goggles',
  MX_GOGGLES_ACCESSORIES: 'MX Goggles Accessories',
  SNOW_ELECTONICS: 'Electronics Snow Goggle',
  SPARE: 'Spare Parts',
  WHATCHES: 'Whatches',
  MASK: 'Face Mask',
  MATERIAL: 'RX/OCP Material',
  MISCELLANEOUS: 'Miscellaneous',
  OTHER: 'Other',
  NOT_DEFINED: 'Not Defined'
}

export const SORTBY_TP_ELEMENTS = {
  RECENT_SHIPMENT: 'mostRecentShipment',
  MODEL_CODE_AZ: 'modelCodeAZ',
  MODEL_CODE_ZA: 'modelCodeZA',
  LATEST_RELEASE: 'latestRelease',
  RELEVANCE: "relevance",
}

export const SHIPMENT_DATES = {
  LAST_WEEK: 'lastWeek',
  LAST_4_WEEKS: 'last4Weeks',
}

export const SHIPMENT_MODELS = {
  NEW_PICKS_FOR_YOU: 'newPicksForYou',
  NEW_MODELS: 'newModels',
  NEW: 'new',
}

export const SORTBY_TP = {
  MOST_RECENT_SHIPMENT: [
    {
      id: SORTBY_TP_ELEMENTS.RECENT_SHIPMENT,
      order: 0,
      checked: true,
      disabled: false,
    },
  ],
  MODEL_CODE_AZ: [
    {
      id: SORTBY_TP_ELEMENTS.MODEL_CODE_AZ,
      order: 0,
      checked: true,
      disabled: false,
    }
  ],
  MODEL_CODE_ZA: [
    {
      id: SORTBY_TP_ELEMENTS.MODEL_CODE_ZA,
      order: 0,
      checked: true,
      disabled: false,
    }
  ],
  LATEST_RELEASE: [
    {
      id: SORTBY_TP_ELEMENTS.LATEST_RELEASE,
      order: 0,
      checked: true,
      disabled: false,
    }
  ],
  RELEVANCE: [
    {
      id: SORTBY_TP_ELEMENTS.RELEVANCE,
      order: 0,
      checked: true,
      disabled: false,
    }
  ]
}

export const INITIAL_FILTER_TP_PLP: ResponseGetProducts = {
  elementsNum: 0,
  items: {
    sortings: [
      { ...SORTBY_TP.MOST_RECENT_SHIPMENT[0] },
      // { ...SORTBY_TP.MODEL_CODE_AZ[0], checked: false },
      // { ...SORTBY_TP.MODEL_CODE_ZA[0], checked: false },
      { ...SORTBY_TP.LATEST_RELEASE[0], checked: false },
    ]
  }
};

export const INITIAL_FILTER_TP_SEARCH = {
  elementsNum: 0,
  items: {
    sortings: [
      { ...SORTBY_TP.RELEVANCE[0] },
      { ...SORTBY_TP.MOST_RECENT_SHIPMENT[0], checked: false },
      { ...SORTBY_TP.MODEL_CODE_AZ[0], checked: false },
      { ...SORTBY_TP.MODEL_CODE_ZA[0], checked: false },
      { ...SORTBY_TP.LATEST_RELEASE[0], checked: false },
    ]
  }
};

export const INITIAL_FILTER_NOVELTIES_SEARCH = {
  elementsNum: 0,
  items: {
    sortings: [
      { ...SORTBY_TP.RELEVANCE[0] },
      { ...SORTBY_TP.MODEL_CODE_AZ[0], checked: false },
      { ...SORTBY_TP.MODEL_CODE_ZA[0], checked: false },
      { ...SORTBY_TP.LATEST_RELEASE[0], checked: false },
    ]
  }
};

export const INITIAL_FILTER_NOVELTIES_STORY_PLP = {
  elementsNum: 0,
  items: {
    sortings: [
      { ...SORTBY_TP.MODEL_CODE_AZ[0] },
      { ...SORTBY_TP.MODEL_CODE_ZA[0], checked: false },
      { ...SORTBY_TP.LATEST_RELEASE[0], checked: false },
    ]
  }
};

export const INITIAL_FILTER_TP_WISHLIST = {
  elementsNum: 0,
  items: {
    sortings: [
      { ...SORTBY_TP.MOST_RECENT_SHIPMENT[0] },
      { ...SORTBY_TP.MODEL_CODE_AZ[0], checked: false },
      { ...SORTBY_TP.MODEL_CODE_ZA[0], checked: false },
      { ...SORTBY_TP.LATEST_RELEASE[0], checked: false },
    ],
    status: [
      {
        id: 'wishlist',
        order: 0,
        checked: true,
        disabled: false,
      }
    ],
  },
};

export class TrainingPillResponse {
  items: Array<TrainingPill> = [];
  elementsNum: number = 0; //numero delle training pills totali che rispettano i filtri applicati
}

export class SearchTrainingPillResponse {
  searchFields: TrainingPill[];
  suggestionFields: TrainingPill[];
  suggestedPhrase: string;
  suggestedFrequency: number;
  suggestedTerms: string[];
  responseFilter: ResponseGetProducts;
}

export const orderDateElementsInitial = [
  {
    id: 7,
    title: 'LAST_X_DAYS',
    checked: false,
    disabled: false
  },
  {
    id: 14,
    title: 'LAST_X_DAYS',
    checked: false,
    disabled: false
  },
  {
    id: 30,
    title: 'LAST_X_DAYS',
    checked: false,
    disabled: false
  },
  {
    id: 'CUSTOM',
    title: 'CUSTOMIZE',
    checked: false,
    disabled: false
  }
]


export const INITIAL_FILTER_LATEST_COLLECTION_PAGE = {
  elementsNum: 0,
  items: {},
};
export const INITIAL_FILTER_BRAND_COLLECTION_PAGE = {
  elementsNum: 0,
  items: {},
};