import React, { useEffect, useState } from 'react';
import { AccordionListElement, ComplexityAccordionList } from '../model/AccordionListElement';
import { FilterEnableMap } from '../model/FilterMap';
import AccordionList from './UI/AccordionList';
import Chip from './UI/Chip';
import Modal from './UI/Modal';
import { Course, getTrainerFullName, LevelEnrichedMap } from '@model/CoursesClass';
import {
  getComplexityCategoryLabel,
  getComplexityCategoryOrder,
  getDurationLabel,
  getScheduleLabel,
  isMandatory,
} from '@utility/Api';
import {
  getDateFixedRangeLabel,
  getExpirationLabel,
  getOfferPlanLabel,
  getPriceLabel,
} from '@utility/ecommerceUtility';
import { UserProfile } from '@model/User';
import { UtagViewPages } from './link-utils';
import { FILTER_CERTIFICATE, FILTER_COURSE, FILTER_OTHERS, PRICE_FILTER_LABELS, PRICE_FILTER_PLAN } from '@utility/const';
import CheckboxExternalChecked from './UI/CheckboxExternalChecked';
import DateRange from './UI/DateRange';
import {
  DATE_CUSTOM_RANGE,
  getMacroCategoryFromComplexityFilter,
  getComplexityFromComplexityFilter,
  isDateSessionRangeFilterApplied,
} from '@utility/filterUtility';
import { getLevelLabel } from '@utility/levelUtility';
import ButtonV2 from '@components/UI/ButtonV2';
import ChevronUp from '@svg-icons/chevron-up.svg';
import { useBreakpointBoolean } from '@hooks/createBreakpoint';
import useElementSize from '@hooks/useElementSize';
import useFilterTopicsMaxHeight from '@hooks/useFilterTopicsMaxHeight';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@redux-actions/';
import { isPersonificationOn } from '../pages_v3/UserGroupSimulation/UserGroupSimulation_PersonificateLogic';
import { buildClickDataTrackingObject, UserTypes } from '@model/TrackingClass';
import TextSkeleton from './skeletons/TextSkeleton';
import { setTimerJoin } from '@redux-actions/';

interface FilterModalProps {
  close: () => void;
  show: boolean;
  filters: any;
  selectedFilters: any;
  toggleOther: (filter: string, type: string, mutualExclusion?: boolean) => void;
  lang: any;
  label: any;
  printLanguagesMap: any;
  clearAllFilters: () => void;
  filterEnableMap: FilterEnableMap;
  labelL1Map: LevelEnrichedMap;
  labelL2Map: LevelEnrichedMap;
  labelComplexityMap: LevelEnrichedMap;
  numCourses: number;
  sortings: any;
  sortBy: string;
  setSortBy: (sortBy: string) => void;
  cancelFilter: () => void;
  userProfile: UserProfile;
  selectedChannel: string;
  categoryName: string;
  subCategoryName: string;
  timeOnTraining: string;
  isTimeOnTrainingLoaded: boolean;
  showCourseModal: boolean;
  searchQuery: string;
  searchType: string;
  category: string;
  filteredCourses: Course[];
  isLoading: boolean;
  hideResultsNumber?: boolean; //hide the results number outside the filter modal
  hideSortingOutsideModal?: boolean; //hide the sorting selection outside the filter modal (normally displayed just in desktop)
  showSortingInModalDesktop?: boolean; //show sorting fields in the filter modal also in desktop viewport
  hideSortingInModal?: boolean; //hide sorting fields in the filter modal also in mobile viewport
  setDateSessionRange: (type: string | number, inFuture: boolean, min?: Date, max?: Date) => void; //change date session range filter
  dateSessionRange: any;
  minMaxSessionDateRange: { min: string; max: string };
  considerDateSessionInTheFuture: boolean;
  showDateSessionFilter: boolean;
  cardVariant?: string; //card to display in CoursesResults
  activatedFiltersAnalytics?: boolean;
}

const ACCORDIONS_KEY = {
  SORT_BY: 'Sort By',
  BRANDS: 'Brands',
  COURSE_TYPE: 'Course Type',
  LANGUAGE: 'Language',
  STATUS: 'Status',
  DURATION: 'Duration',
  TEACHER: 'Teacher',
  LOCATION: 'Location',
  SCHEDULE: 'Schedule',
  PRICE: 'Price',
  OFFER_PLAN: 'Offer Plan',
  EXPIRATION: 'Expiration',
  DATE: 'Date',
};

const FilterModal: React.FC<FilterModalProps> = ({
  close,
  show,
  filters,
  selectedFilters,
  toggleOther,
  lang,
  label,
  printLanguagesMap,
  clearAllFilters,
  filterEnableMap,
  labelL1Map,
  labelL2Map,
  labelComplexityMap,
  numCourses,
  sortings,
  sortBy,
  setSortBy,
  cancelFilter,
  userProfile,
  selectedChannel,
  categoryName,
  subCategoryName,
  timeOnTraining,
  isTimeOnTrainingLoaded,
  showCourseModal,
  searchQuery,
  searchType,
  category,
  isLoading,
  filteredCourses,
  showSortingInModalDesktop,
  hideSortingInModal,
  setDateSessionRange,
  dateSessionRange,
  minMaxSessionDateRange,
  considerDateSessionInTheFuture,
  showDateSessionFilter,
  activatedFiltersAnalytics,
}): React.ReactElement => {
  const [sortingElements, setSortingElements] = useState(new Array<AccordionListElement>());
  const [sectionElements, setSectionElements] = useState(new Array<AccordionListElement>());
  const coursesMap = useSelector(state => state.course.coursesMap);
  const [brandElements, setBrandElements] = useState(new Array<AccordionListElement>());
  const [complexityElems, setComplexityElems] = useState(new Array<ComplexityAccordionList>());
  const [languageElements, setLanguageElements] = useState(new Array<AccordionListElement>());
  const [statusElements, setStatusElements] = useState(new Array<AccordionListElement>());
  const [durationElements, setDurationElements] = useState(new Array<AccordionListElement>());
  const [teacherElements, setTeacherElements] = useState(new Array<AccordionListElement>());
  const [locationElements, setLocationElements] = useState(new Array<AccordionListElement>());
  const [scheduleElements, setScheduleElements] = useState(new Array<AccordionListElement>());
  const [othersElement, setOthersElement] = useState<AccordionListElement>(
    new AccordionListElement()
  );
  const [othersElementCertificate, setOthersElementCertificare] = useState<AccordionListElement>(
    new AccordionListElement()
  );
  
  const [el360Courses, setEl360Courses] = useState<AccordionListElement>(
    new AccordionListElement()
  );
  const [anyOtherBrandOtherAll, setAnyOtherBrandOtherAll] = useState<boolean>(false);
  const [priceElements, setPriceElements] = useState(new Array<AccordionListElement>());
  const [offerPlanElements, setOfferPlanElements] = useState(new Array<AccordionListElement>());
  const [expirationElements, setExpirationElements] = useState(new Array<AccordionListElement>());
  const [dateRangeFixedElements, setDateFixedRangeElements] = useState(
    new Array<AccordionListElement>()
  );

  const [range, setRange] = useState<any>({
    startDate: new Date(considerDateSessionInTheFuture ? 0 : 32505368793000),
    endDate: new Date(considerDateSessionInTheFuture ? 0 : 32505368793000),
    key: 'selection',
  });
  const [dateAccordionNum, setDateAccordionNum] = useState(0);

  const [showReset, setShowReset] = useState(false);
  const [currentOpenTab, setCurrentOpenTab] = useState<string | null>(null);
  const [showCheckboxRecordingAvailable, setShowCheckboxRecordingAvailable] = useState(false);
  const [showCheckboxCertificateaAvailable, setShowCheckboxCertificateAvailable] = useState(false);
  const [showCheckboxEl360, setShowCheckboxEl360] = useState(false);
  const [showAllSections, setShowAllSections] = useState<boolean>(false);
  const [topicsRef, topicsSize] = useElementSize();
  const topicsMaxHeight = useFilterTopicsMaxHeight();
  const dispatch = useDispatch();

  useEffect(() => {
    let sectionElemsTemp: Array<AccordionListElement> = new Array<AccordionListElement>();
    let brandElemsTemp: Array<AccordionListElement> = new Array<AccordionListElement>();
    let complexityElemsTemp: { [id: string]: Array<AccordionListElement> } = {};
    let complexityElemsList: ComplexityAccordionList[] = [];
    let languageElemsTemp: Array<AccordionListElement> = new Array<AccordionListElement>();
    let statusElemsTemp: Array<AccordionListElement> = new Array<AccordionListElement>();
    let durationElemsTemp: Array<AccordionListElement> = new Array<AccordionListElement>();
    let teacherElemsTemp: Array<AccordionListElement> = new Array<AccordionListElement>();
    let locationElemsTemp: Array<AccordionListElement> = new Array<AccordionListElement>();
    let scheduleElemsTemp: Array<AccordionListElement> = new Array<AccordionListElement>();
    let othersElemTemp: AccordionListElement = new AccordionListElement();
    let othersElemCertificateTemp: AccordionListElement = new AccordionListElement();
    let el360Temp: AccordionListElement = new AccordionListElement();
    let priceElemsTemp: Array<AccordionListElement> = new Array<AccordionListElement>();
    let offerPlanElemsTemp: Array<AccordionListElement> = new Array<AccordionListElement>();
    let expirationElemsTemp: Array<AccordionListElement> = new Array<AccordionListElement>();
    let dateFixedRangeElemsTemp: Array<AccordionListElement> = new Array<AccordionListElement>();
    let showCheckboxRecordingAvailableTemp: boolean = false;
    let showCheckBoxEl360Temp: boolean = false;
    let showCheckboxCertificateAvailableTemp: boolean = false;

    let showResetTemp: boolean = false;


    if (filters) {
      if (filters.sections) {
        filters.sections.forEach(item => {
          let sectionElement = new AccordionListElement();
          sectionElement.id = item;
          sectionElement.title = getLevelLabel(item, labelL2Map) || item;
          sectionElement.checked = !!selectedFilters.section[item];
          sectionElement.toggleCheck = () => toggleOther(item, 'section');
          sectionElement.disabled = !filterEnableMap.section[item];

          if (!!selectedFilters.section[item]) {
            showResetTemp = true;
          }

          sectionElemsTemp.push(sectionElement);
        });
      }

      if (filters.brands) {
        filters.brands.forEach(item => {
          let brandElement = new AccordionListElement();
          brandElement.id = item;
          brandElement.title = getLevelLabel(item, labelL1Map) || item;
          brandElement.checked = !!selectedFilters.brand[item];
          brandElement.toggleCheck = () => toggleOther(item, 'brand');
          brandElement.disabled = !filterEnableMap.brand[item];

          if (!!selectedFilters.brand[item]) {
            showResetTemp = true;
          }

          brandElemsTemp.push(brandElement);
        });
      }

      if (filters.complexities) {
        filters.complexities.forEach(complexity => {
          const macroCategory = getMacroCategoryFromComplexityFilter(complexity);
          const complexityId = getComplexityFromComplexityFilter(complexity);

          if (!complexityElemsTemp[macroCategory]) {
            complexityElemsTemp[macroCategory] = [];
          }

          let complexityElement = new AccordionListElement();
          complexityElement.id = complexity;
          complexityElement.title = getLevelLabel(complexityId, labelComplexityMap) || complexityId;
          complexityElement.checked = !!selectedFilters.complexity?.[complexity];
          complexityElement.toggleCheck = () => toggleOther(complexity, 'complexity');
          complexityElement.disabled = !filterEnableMap.complexity?.[complexity];

          complexityElement.complexityId = complexityId;

          if (!!selectedFilters.complexity?.[complexity]) {
            showResetTemp = true;
          }

          complexityElemsTemp[macroCategory].push(complexityElement);
        });
      }

      //sort complexities
      if (labelComplexityMap) {
        for (let macroCategory in complexityElemsTemp) {
          complexityElemsTemp[macroCategory].sort(
            (a, b) =>
              labelComplexityMap[a.complexityId]?.order - labelComplexityMap[b.complexityId]?.order
          );

          const title = getComplexityCategoryLabel(macroCategory, lang);
          const order = getComplexityCategoryOrder(macroCategory);

          complexityElemsList.push({
            elements: complexityElemsTemp[macroCategory],
            title: title,
            order: order,
            category: macroCategory,
          });
        }
      }

      //sort complexity category accordions
      complexityElemsList.sort((a, b) => a.order - b.order);

      if (filters.language) {
        filters.language.forEach(item => {
          let languageElement = new AccordionListElement();
          languageElement.id = item;
          languageElement.title = printLanguagesMap[item];
          languageElement.checked = !!selectedFilters.language[item];
          languageElement.toggleCheck = () => toggleOther(item, 'language');
          languageElement.disabled = !filterEnableMap.language[item];

          if (!!selectedFilters.language[item]) {
            showResetTemp = true;
          }

          languageElemsTemp.push(languageElement);
        });
      }

      if (filters.status) {
        filters.status.forEach(item => {
          let statusElement = new AccordionListElement();
          statusElement.id = item;
          statusElement.title = label[item];
          statusElement.checked = !!selectedFilters.status[item];
          statusElement.toggleCheck = () => toggleOther(item, 'status');
          statusElement.disabled = !filterEnableMap.status[item];

          if (!!selectedFilters.status[item]) {
            showResetTemp = true;
          }

          statusElemsTemp.push(statusElement);
        });
      }

      if (filters.duration) {
        filters.duration.forEach(item => {
          let durationElement = new AccordionListElement();
          durationElement.id = item;
          durationElement.title = getDurationLabel(item, lang);
          durationElement.checked = !!selectedFilters.duration[item];
          durationElement.toggleCheck = () => toggleOther(item, 'duration');
          durationElement.disabled = !filterEnableMap.duration[item];

          if (!!selectedFilters.duration[item]) {
            showResetTemp = true;
          }

          durationElemsTemp.push(durationElement);
        });
      }

      if (filters.teacher) {
        filters.teacher.forEach(item => {
          let teacherElement = new AccordionListElement();
          teacherElement.id = item.username;
          teacherElement.title = getTrainerFullName(item);
          teacherElement.checked = !!selectedFilters.teacher[item.username];
          teacherElement.toggleCheck = () => toggleOther(item.username, 'teacher');
          teacherElement.disabled = !filterEnableMap.teacher[item.username];

          if (!!selectedFilters.teacher[item.username]) {
            showResetTemp = true;
          }

          teacherElemsTemp.push(teacherElement);
        });
      }

      if (filters.location) {
        filters.location.forEach(item => {
          let locationElement = new AccordionListElement();
          locationElement.id = item;
          locationElement.title = item;
          locationElement.checked = !!selectedFilters.location[item];
          locationElement.toggleCheck = () => toggleOther(item, 'location');
          locationElement.disabled = !filterEnableMap.location[item];

          if (!!selectedFilters.location[item]) {
            showResetTemp = true;
          }

          locationElemsTemp.push(locationElement);
        });
      }

      if (filters.schedule) {
        filters.schedule.forEach(item => {
          let scheduleElement = new AccordionListElement();
          scheduleElement.id = item;
          scheduleElement.title = getScheduleLabel(item, lang);
          scheduleElement.checked = !!selectedFilters.schedule[item];
          scheduleElement.toggleCheck = () => toggleOther(item, 'schedule');
          scheduleElement.disabled = !filterEnableMap.schedule[item];

          if (!!selectedFilters.schedule[item]) {
            showResetTemp = true;
          }

          scheduleElemsTemp.push(scheduleElement);
        });
      }

      if (filters.others?.includes(FILTER_OTHERS.RECORDING_AVAILABLE)) {
        const itemOther = FILTER_OTHERS.RECORDING_AVAILABLE;
        othersElemTemp.id = itemOther;
        othersElemTemp.title = lang.LIVESTREAM_RECORDING_AVAILABLE;
        othersElemTemp.checked = !!selectedFilters.others[itemOther];
        othersElemTemp.toggleCheck = value => {
          //toggle only if the value is different respect the actual
          if (value !== othersElement.checked) {
            toggleOther(itemOther, 'others');
          }
        };
        othersElemTemp.disabled = !filterEnableMap.others[itemOther];

        if (!!selectedFilters.others[itemOther]) {
          showResetTemp = true;
        }

        showCheckboxRecordingAvailableTemp = true;
      }

      if (filters.certificates?.includes(FILTER_CERTIFICATE.CERTIFICATE_AVAILABLE)) { //CERTIFICATE
        const itemOther = FILTER_CERTIFICATE.CERTIFICATE_AVAILABLE;
        othersElemCertificateTemp.id = itemOther;
        othersElemCertificateTemp.title = lang.FILTER_CERTIFICATE_AVAILABLE;
        othersElemCertificateTemp.checked = !!selectedFilters.certificates[itemOther];
        othersElemCertificateTemp.toggleCheck = value => {
          //toggle only if the value is different respect the actual
          if (value !== othersElementCertificate.checked) {
            toggleOther(itemOther, 'certificates');
          }
        };
        othersElemCertificateTemp.disabled = !filterEnableMap.certificates[itemOther];

        if (!!selectedFilters.certificates[itemOther]) {
          showResetTemp = true;
        }

        showCheckboxCertificateAvailableTemp = true;
      }

      if (filters.el360?.includes(FILTER_COURSE.EL_360)) {
        const item = FILTER_COURSE.EL_360;
        el360Temp.id = item;
        el360Temp.title = lang.EL360_LABEL;
        el360Temp.checked = !!selectedFilters.el360[item];
        el360Temp.toggleCheck = value => {

          if (value !== el360Courses.checked) {
            toggleOther(item, 'el360');
          }
        };
        el360Temp.disabled = !filterEnableMap.el360[item];

        if (!!selectedFilters.el360[item]) {
          showResetTemp = true;
        }
        showCheckBoxEl360Temp = true;
      }

      if (filters.price) {
        filters.price.forEach(item => {
          let priceElement = new AccordionListElement();
          priceElement.id = item;
          priceElement.title = getPriceLabel(item, lang , userProfile?.ecommerceInfo?.currency, userProfile?.preferredLang) || item;
          priceElement.checked = !!selectedFilters.price[item];
          priceElement.toggleCheck = () => toggleOther(item, 'price');
          priceElement.disabled = !filterEnableMap.price[item];
          priceElement.order = PRICE_FILTER_LABELS[item]?.index;

          if (!!selectedFilters.price[item]) {
            showResetTemp = true;
          }

          priceElemsTemp.push(priceElement);
        });
      }

      if (filters.offerPlan) {
        filters.offerPlan.forEach(item => {
          let offerPlanElement = new AccordionListElement();
          offerPlanElement.id = item;
          offerPlanElement.title = getOfferPlanLabel(item, lang) || item;
          offerPlanElement.checked = !!selectedFilters.offerPlan[item];
          offerPlanElement.toggleCheck = () => toggleOther(item, 'offerPlan');
          offerPlanElement.disabled = !filterEnableMap.offerPlan[item];
          offerPlanElement.order = PRICE_FILTER_PLAN[item]?.index;

          if (!!selectedFilters.offerPlan[item]) {
            showResetTemp = true;
          }

          offerPlanElemsTemp.push(offerPlanElement);
        });
      }

      if (filters.expiration) {
        filters.expiration.forEach(item => {
          let expirationElement = new AccordionListElement();
          expirationElement.id = item;
          expirationElement.title = getExpirationLabel(item, lang) || item;
          expirationElement.checked = !!selectedFilters.expiration[item];
          expirationElement.toggleCheck = () => toggleOther(item, 'expiration');
          expirationElement.disabled = !filterEnableMap.expiration[item];

          if (!!selectedFilters.expiration[item]) {
            showResetTemp = true;
          }

          expirationElemsTemp.push(expirationElement);
        });
      }

      if (showDateSessionFilter && filters.dateFixedRange) {
        filters.dateFixedRange.forEach(item => {
          let dateFixedRangeElement = new AccordionListElement();
          dateFixedRangeElement.id = item;
          dateFixedRangeElement.title = getDateFixedRangeLabel(item, lang) || item;
          dateFixedRangeElement.checked = !!selectedFilters.dateFixedRange[item];
          dateFixedRangeElement.toggleCheck = () => {
            toggleOther(item, 'dateFixedRange', true);

            //if a user select a fixed range and the custom range is enabled --> disable custom range
            if (dateSessionRange?.type === DATE_CUSTOM_RANGE) {
              setDateSessionRange(DATE_CUSTOM_RANGE, considerDateSessionInTheFuture);
            }
          };
          dateFixedRangeElement.disabled = !filterEnableMap.dateFixedRange[item];

          if (!!selectedFilters.dateFixedRange[item]) {
            showResetTemp = true;
          }

          dateFixedRangeElemsTemp.push(dateFixedRangeElement);
        });
      }
    }

    if (showDateSessionFilter) {
      //calculate the date accordion number
      let dateAccordionNumTemp = 0;
      dateFixedRangeElemsTemp?.forEach(a => {
        if (a.checked) {
          dateAccordionNumTemp++;
        }
      });

      //if custom date range is active and selected
      if (isDateSessionRangeFilterApplied(dateSessionRange)) {
        showResetTemp = true;
        dateAccordionNumTemp++;
      }
      setDateAccordionNum(dateAccordionNumTemp);
    }

    sectionElemsTemp = orderBy(sectionElemsTemp, ['title']);
    setSectionElements(sectionElemsTemp);
    brandElemsTemp = orderBy(brandElemsTemp, ['title']);
    setBrandElements(brandElemsTemp);
    setComplexityElems(complexityElemsList);
    setLanguageElements(languageElemsTemp);
    setStatusElements(statusElemsTemp);
    setDurationElements(durationElemsTemp);
    setTeacherElements(teacherElemsTemp);
    setLocationElements(locationElemsTemp);
    setScheduleElements(scheduleElemsTemp);
    setOthersElement(othersElemTemp);
    setOthersElementCertificare(othersElemCertificateTemp);
    setEl360Courses(el360Temp);
    setPriceElements(priceElemsTemp.sort((a, b) => a.order - b.order));
    setOfferPlanElements(offerPlanElemsTemp.sort((a, b) => a.order - b.order));
    setExpirationElements(expirationElemsTemp);
    setShowCheckboxRecordingAvailable(showCheckboxRecordingAvailableTemp);
    setShowCheckboxCertificateAvailable(showCheckboxCertificateAvailableTemp);
    setShowCheckboxEl360(showCheckBoxEl360Temp);
    // console.log('dateFixedRangeElemsTemp', dateFixedRangeElemsTemp)
    dateFixedRangeElemsTemp = orderBy(dateFixedRangeElemsTemp, ['title']);
    setDateFixedRangeElements(
      dateFixedRangeElemsTemp.sort((a, b) => {
        const aDaysNum = +a.id.replace(/_future|_past/, '');
        const bDaysNum = +b.id.replace(/_future|_past/, '');

        return aDaysNum - bDaysNum;
      })
    );

    setShowReset(showResetTemp);

    //sorting
    if (sortings) {
      const sortingAccordion: Array<AccordionListElement> = new Array<AccordionListElement>();
      Object.keys(sortings).forEach(key => {
        let sortObj = sortings[key];
        let obj = new AccordionListElement();
        obj.id = sortObj.value;
        obj.title = sortObj.labelMobile;
        obj.checked = key === sortBy;
        obj.toggleCheck = () => setSortBy(key);
        obj.disabled = sortObj.disabled ? true : false;

        sortingAccordion.push(obj);
      });
      setSortingElements(sortingAccordion);
    }
  }, [filters, filterEnableMap, lang, labelL1Map, labelL2Map, labelComplexityMap]);


  

  const handleSelect = (value: Range) => {
    // console.log('value', value);
    setRange(value.selection);
    const startDate = value.selection?.startDate;
    const endDate = value.selection?.endDate;
    if (startDate && endDate) {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 0);

      setDateSessionRange(DATE_CUSTOM_RANGE, considerDateSessionInTheFuture, startDate, endDate);
    }
  };

  const handleDateSessionRange = (type: string | number) => {
    if (type === DATE_CUSTOM_RANGE && dateSessionRange?.type !== DATE_CUSTOM_RANGE) {
      setDateSessionRange(
        DATE_CUSTOM_RANGE,
        considerDateSessionInTheFuture,
        range.startDate,
        range.endDate
      );

      dateRangeFixedElements?.forEach(a => {
        if (a.checked) {
          a.toggleCheck();
        }
      });
    } else {
      setDateSessionRange(type, considerDateSessionInTheFuture);
    }
  };

  const handleClose = () => {
    setCurrentOpenTab(null);
    close();
    //document?.querySelector('html')?.classList?.remove('block-scroll');
  };

  useEffect(() => {
    console.log(`categoryName`, categoryName);
    console.log(
      `isLoading, sortBy, userProfile, isTimeOnTrainingLoaded, selectedChannel, selectedFilters, subCategoryName, categoryName, searchQuery, searchType, numCourses`,
      {
        sortBy,
        userProfile,
        isTimeOnTrainingLoaded,
        show,
        showCourseModal,
        selectedChannel,
        selectedFilters,
        subCategoryName,
        categoryName,
        searchQuery,
        searchType,
        numCourses,
        isLoading,
        filteredCourses,
      }
    );
    // analytics
    if (
      !isTimeOnTrainingLoaded ||
      isLoading ||
      numCourses === 0 ||
      showCourseModal ||
      show ||
      !subCategoryName ||
      !activatedFiltersAnalytics
    ) {
      return;
    }
    if (!!userProfile && categoryName !== 'WISHLIST_PAGE_NAME_LABEL' && categoryName) {
      const selectedFiltersTypes: string[] = Object.keys(selectedFilters).filter(
        filter => Object.keys(selectedFilters[filter]).length > 0
      );
      const selectedFiltersValues: string[] = selectedFiltersTypes?.map((keyFilter): string =>
        Object.keys(selectedFilters[keyFilter]).join(',')
      );

      if (selectedChannel && selectedChannel !== 'all') {
        selectedFiltersTypes.push('channel');
        selectedFiltersValues.push(selectedChannel);
      }
      //const page_subcategory = subCategoryName ? { page_subcategory: subCategoryName } : {};

      // const UtagViewPageFilter =
      //   category === 'search'
      //     ? {
      //       ...UtagViewPages.SEARCH_RESULTS,
      //       Search_Keyword: searchQuery,
      //       Search_ResultItemsQnt: numCourses?.toString(),
      //       Search_Type: searchType || 'text',
      //     }
      //     : UtagViewPages.CATALOGUE;

      // console.log(
      //   `%c Filter Modal`,
      //   'color: #bada55; font-size: 20px',
      //   new Date().toISOString().slice(11, 23),
      //   {
      //     ...UtagViewPageFilter,
      //     Page_Language: userProfile?.preferredLang,
      //     Page_Country: userProfile?.country,
      //     User_LoginStatus: userProfile?.senderSystem,
      //     User_Id: userProfile?.username,
      //     user_timeontraining: timeOnTraining,
      //     browse_refine_type: selectedFiltersTypes,
      //     browse_refine_value: selectedFiltersValues,
      //     browse_refine_sorting: sortBy,
      //     Page_Section3: categoryName,
      //     Products_Id_Array: filteredCourses.map(course => course.courseIdMaster),
      //     Products_ModelName_Array: filteredCourses.map(course => course.courseFullName),
      //     product_format: filteredCourses.reduce((acc, course) => [...acc, ...course.ctype], []), // flat
      //     Products_Category_Array: filteredCourses.reduce((acc, course) => {
      //       const catalogTypes = Array.isArray(course.catalogTypes)
      //         ? course.catalogTypes
      //         : [course.catalogTypes || ''];
      //       return [...acc, ...catalogTypes];
      //     }, []), // flat
      //     Products_Mandatory_Array: filteredCourses.map(course => (isMandatory(course) ? 'Y' : 'N')),
      //     ...page_subcategory,
      //     Page_DeviceType: 'web',
      //     Order_Currency: userProfile?.ecommerceInfo?.currency,
      //     User_MoodleID: userProfile?.moodleId,
      //   }
      // );

      // window?.utag?.view({
      //   ...UtagViewPageFilter,
      //   Page_Language: userProfile?.preferredLang,
      //   Page_Country: userProfile?.country,
      //   User_LoginStatus: userProfile?.senderSystem,
      //   User_Id: userProfile?.username,
      //   user_timeontraining: timeOnTraining,
      //   browse_refine_type: selectedFiltersTypes,
      //   browse_refine_value: selectedFiltersValues,
      //   browse_refine_sorting: sortBy,
      //   Page_Section3: categoryName,
      //   Products_Id_Array: filteredCourses.map(course => course.courseIdMaster),
      //   Products_ModelName_Array: filteredCourses.map(course => course.courseFullName),
      //   product_format: filteredCourses.reduce((acc, course) => [...acc, ...course.ctype], []), // flat
      //   Products_Category_Array: filteredCourses.reduce((acc, course) => {
      //     const catalogTypes = Array.isArray(course.catalogTypes)
      //       ? course.catalogTypes
      //       : [course.catalogTypes || ''];
      //     return [...acc, ...catalogTypes];
      //   }, []), // flat
      //   Products_Mandatory_Array: filteredCourses.map(course => (isMandatory(course) ? 'Y' : 'N')),
      //   ...page_subcategory,
      //   Page_DeviceType: 'web',
      //   Order_Currency: userProfile?.ecommerceInfo?.currency,
      //   User_MoodleID: userProfile?.moodleId,
      // });
    }
  }, [
    sortBy,
    userProfile,
    isTimeOnTrainingLoaded,
    isLoading,
    selectedChannel,
    subCategoryName,
    categoryName,
    searchQuery,
    searchType,
    numCourses,
    show,
    showCourseModal,
    selectedFilters,
    filteredCourses,
    activatedFiltersAnalytics,
  ]);


  useEffect(() => {
    dispatch(setTimerJoin()); // Ricalcola il timer per i corsi filtrati
  }, [filteredCourses]);

  const toggleAccordion = (type, value) =>
    value ? setCurrentOpenTab(type) : setCurrentOpenTab(null);

   /* const isGetCourseCompleted = useSelector(state => state.course.isGetCourseCompleted);
    const isLoadingGetUserRelevantSession = useSelector(state => state.utils.isLoadingGetUserRelevantSessions);
    const dispatch = useDispatch();
  
    useEffect(()=>{
       if(!isLoadingGetUserRelevantSession && isGetCourseCompleted){
         dispatch(actions.getUserRelevantSessions());
       }
     },[userProfile?.preferredLang,isGetCourseCompleted])*/

    const isLoadingGetFiltersCertificate = useSelector(state => state.utils.isLoadingGetFiltersCertificate);

  return (
    <>
      <Modal
        blur="always"
        blurIntensity="07"
        close={() => {
          cancelFilter();
          handleClose();
        }}
        show={show}
        className="fillAvailable"
        contentClassName="modal__content--centered"
        isCloseIconSquare={true}
      >
        <section className="filter-modal" aria-label={lang.FILTERS_LABEL}>
          <div className="filter-modal__second-header">
            <span className="desktop">{lang.FILTERS_LABEL}</span>
            <span className="mobile">{lang.FILTERS_LABEL}</span>
          </div>
          <div className="filter-modal__accordions">
            {sectionElements && sectionElements.length > 0 && (
              <>
                <span className="filter-modal__topics--second-title">{lang.TOPICS}</span>
                <div
                  className="filter-modal__topics-container"
                  style={{
                    maxHeight: showAllSections ? topicsSize.height.toString() + 'px' : undefined,
                  }}
                >
                  <div className="filter-modal__topics more-space" ref={topicsRef}>
                    {sectionElements.map((item, index) => (
                      <Chip
                        className="chip-modal"
                        key={item.id + '_' + index}
                        label={item.title}
                        showClose
                        checked={selectedFilters.section[item.id]}
                        toggle={() => toggleOther(item.id, 'section')}
                        disabled={!filterEnableMap.section[item.id]}
                      />
                    ))}
                  </div>
                </div>
                {topicsSize.height > topicsMaxHeight ? (
                  <div className="filter-modal__view-more-container">
                    <ButtonV2
                      variant="text-btn-no-arrow"
                      className="filter-modal__view-more-btn"
                      small
                      handleClick={() => setShowAllSections(!showAllSections)}
                    >
                      {showAllSections ? lang.VIEW_LESS : lang.VIEW_MORE}
                      <ChevronUp
                        className={
                          showAllSections
                            ? 'filter-modal__chevron'
                            : 'filter-modal__chevron--expand'
                        }
                      />
                    </ButtonV2>
                  </div>
                ) : (
                  <div className="filter-modal__no-view-more"></div>
                )}
              </>
            )}

            {showDateSessionFilter && (
              <AccordionList
                elements={[]}
                title={lang?.FILTER_DATE + (dateAccordionNum > 0 ? ` (${dateAccordionNum})` : '')}
                toggleAccordion={toggleAccordion}
                name={ACCORDIONS_KEY.DATE}
                expand={currentOpenTab === ACCORDIONS_KEY.DATE}
                showChildren
                isFilterModal
              >
                <div className="filter-modal__topics">
                  {dateRangeFixedElements.map((item, index) => (
                    <Chip
                      key={'ORDER_DATE_' + item.id + '_' + index}
                      label={item.title}
                      showClose
                      checked={selectedFilters.dateFixedRange[item.id]}
                      toggle={item.toggleCheck}
                      disabled={!filterEnableMap.dateFixedRange[item.id]}
                    />
                  ))}
                  <Chip
                    key={'ORDER_DATE_CUSTOM'}
                    label={lang?.CUSTOMIZE}
                    showClose
                    checked={dateSessionRange?.type === DATE_CUSTOM_RANGE}
                    toggle={() => handleDateSessionRange(DATE_CUSTOM_RANGE)}
                  />
                </div>
                {dateSessionRange?.type === DATE_CUSTOM_RANGE && (
                  <div className="filter-modal__daterange-container">
                    <DateRange
                      classNameContainer="width-100-mobile"
                      ranges={[range]}
                      onChange={handleSelect}
                      maxDate={
                        minMaxSessionDateRange?.max
                          ? new Date(minMaxSessionDateRange.max)
                          : new Date()
                      }
                      minDate={
                        minMaxSessionDateRange?.min
                          ? new Date(minMaxSessionDateRange.min)
                          : new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 61)
                      }
                    />
                  </div>
                )}
              </AccordionList>
            )}
            <AccordionList
              elements={brandElements}
              title={lang.FILTER_BRAND}
              toggleAccordion={toggleAccordion}
              name={ACCORDIONS_KEY.BRANDS}
              expand={currentOpenTab === ACCORDIONS_KEY.BRANDS}
              isFilterModal
            />
            {complexityElems?.length > 0 &&
              complexityElems.map(complexityPerCategory => (
                <AccordionList
                  elements={complexityPerCategory.elements}
                  title={complexityPerCategory.title}
                  toggleAccordion={toggleAccordion}
                  name={complexityPerCategory.category}
                  expand={currentOpenTab === complexityPerCategory.category}
                  key={complexityPerCategory.category}
                  isFilterModal
                  otherElementRecording = {othersElement}
                  showCheckboxRecordingAvailable= {showCheckboxRecordingAvailable}
                />
              ))}
            <AccordionList
              elements={statusElements}
              title={lang.FILTER_STATUS}
              toggleAccordion={toggleAccordion}
              name={ACCORDIONS_KEY.STATUS}
              expand={currentOpenTab === ACCORDIONS_KEY.STATUS}
              isFilterModal
            />
            {expirationElements.length > 0 && (
              <AccordionList
                elements={expirationElements}
                title={lang.EXPIRING_DATE}
                toggleAccordion={toggleAccordion}
                name={ACCORDIONS_KEY.EXPIRATION}
                expand={currentOpenTab === ACCORDIONS_KEY.EXPIRATION}
                isFilterModal
              />
            )}
            {offerPlanElements.length > 0 && (
              <AccordionList
                elements={offerPlanElements}
                title={lang.OFFER_PLAN}
                toggleAccordion={toggleAccordion}
                name={ACCORDIONS_KEY.OFFER_PLAN}
                expand={currentOpenTab === ACCORDIONS_KEY.OFFER_PLAN}
                isFilterModal
              />
            )}
            {priceElements.length > 0 && (
              <AccordionList
                elements={priceElements}
                title={lang.PRICES}
                toggleAccordion={toggleAccordion}
                name={ACCORDIONS_KEY.PRICE}
                expand={currentOpenTab === ACCORDIONS_KEY.PRICE}
                isFilterModal
              />
            )}
            <AccordionList
              elements={durationElements}
              title={lang.TIME_DURATION_LABEL}
              toggleAccordion={toggleAccordion}
              name={ACCORDIONS_KEY.DURATION}
              expand={currentOpenTab === ACCORDIONS_KEY.DURATION}
              isFilterModal
            />
            <AccordionList
              elements={teacherElements}
              title={lang.SPEAKER}
              toggleAccordion={toggleAccordion}
              name={ACCORDIONS_KEY.TEACHER}
              expand={currentOpenTab === ACCORDIONS_KEY.TEACHER}
              isFilterModal
            />
            <AccordionList
              elements={scheduleElements}
              title={lang.SCHEDULE}
              toggleAccordion={toggleAccordion}
              name={ACCORDIONS_KEY.SCHEDULE}
              expand={currentOpenTab === ACCORDIONS_KEY.SCHEDULE}
              isFilterModal
            />
            <AccordionList
              elements={languageElements}
              title={lang.LANGUAGE_LABEL}
              toggleAccordion={toggleAccordion}
              name={ACCORDIONS_KEY.LANGUAGE}
              expand={currentOpenTab === ACCORDIONS_KEY.LANGUAGE}
              isFilterModal
            />
            <AccordionList
              elements={locationElements}
              title={lang.LOCATION}
              toggleAccordion={toggleAccordion}
              name={ACCORDIONS_KEY.LOCATION}
              expand={currentOpenTab === ACCORDIONS_KEY.LOCATION}
              isFilterModal
            />
            <div className='container-checkbox'>
            {showCheckboxEl360 &&
            <CheckboxExternalChecked
              className="filter-modal__checkbox-el360"
              text={el360Courses.title}
              checked={el360Courses.checked}
              disabled={el360Courses.disabled}
              name={el360Courses.id}
              onChange={el360Courses.toggleCheck}
            />
            }
            {showCheckboxCertificateaAvailable && !isLoadingGetFiltersCertificate &&  (
              <CheckboxExternalChecked
                className="filter-modal__checkbox-certificate"
                text={othersElementCertificate.title}
                checked={othersElementCertificate.checked}
                disabled={othersElementCertificate.disabled}
                name={othersElementCertificate.id}
                onChange={othersElementCertificate.toggleCheck}
              />
            )}
            {isLoadingGetFiltersCertificate && 
            (   
            <div className='filter-modal__skeleton-filter-certificates-modal'><TextSkeleton className="phone-and-tablet-portrait" width={200} height={30} borderRadius={10} />
              <TextSkeleton className="desktop-and-tablet-landscape" width={200} height={30} borderRadius={10} /></div>)}
             </div>
          </div>
        </section>
        <br />
      </Modal>
      {show && (
        <div className="filter-modal__button box-sticky-bar-width">
          <div className="inner-box-sticky">
            <div>
              {showReset && clearAllFilters && (
                <ButtonV2
                  variant="tertiary"
                  small
                  className="button-no-back filter-modal__action"
                  handleClick={clearAllFilters}
                >
                  {lang.RESET_FILTERS}
                </ButtonV2>
              )}
            </div>
            <div>
              <ButtonV2 variant="secondary" small handleClick={ ()=>{buildClickDataTrackingObject("filter_applied");handleClose()}}>
                {lang.N_RESULTS_FOR_YOUR_SEARCH.replace('{N}', numCourses)}
              </ButtonV2>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

FilterModal.defaultProps = {
  show: false,
  filterEnableMap: new FilterEnableMap(),
};

export default FilterModal;
