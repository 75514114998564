import React, { memo, useEffect, useState } from 'react';
import { Course, CourseAssociatedSession, LangMap } from '@model/CoursesClass';
import useTime from '@hooks/useTime';
import { useSelector } from '@hooks/redux';
import { getLocalDateFromString } from '@utility/CalendarUtility';
import { UserProfile } from '@model/User';
import BadgeCourse from '@components/UI/BadgeCourse';
import TagBadge from '@components/UI/TagBadge';
import { BADGE, BADGE_PRIORITY } from '@utility/const';
import { isAnEvent, isBookedCourse, isMandatory, isNewLivestream, isVCInTheFuture } from '@utility/Api';
import { isNewLivestreamLiveNow } from '@utility/VCUtility';

const ONE_SECOND = 1000;
const ONE_MINUTE = 60 * ONE_SECOND;
const ONE_HOUR = 60 * ONE_MINUTE;

const formatTime = (time: number) => {
  const minutes = Math.floor((time % ONE_HOUR) / ONE_MINUTE);
  const seconds = Math.floor((time % ONE_MINUTE) / ONE_SECOND);
  const minutesString = minutes.toString().padStart(2, '0');
  const secondsString = seconds.toString().padStart(2, '0');

  return `${minutesString}:${secondsString}`;
};

const formatTimeForVC = (time: number) => {
  const minutes = Math.floor((time % ONE_HOUR) / ONE_MINUTE);
  const seconds = Math.floor((time % ONE_MINUTE) / ONE_SECOND);
  let minutesString;
  if(minutes<1 ){ //seconds
    minutesString = seconds.toString().padStart(2, '0');
    return '00:' + `${minutesString}`
  }
  else{ //minutes
    minutesString = minutes.toString().padStart(2, '0');
    return `${minutesString}`
  }

};

const threshold15Min = ONE_MINUTE * 15;

export type BottomLabelLiveProps = {
  timeStart: string;
  timeEnd: string;
  timeCourseStart: string;
  timeCourseEnd: string;
  course: Course;
  variant?: 'medium';
  lang: LangMap;
  defaultLabel?: string;
  defaultColor?: string;
  defaultPriority?: number;
  showLiveBadge?: boolean;
  showLiveCountdown?: boolean;
  useTagBadge?: boolean;
  className?: string;
  showBadgeVC?: boolean;
};
const BadgeLabelLive = ({
  timeStart,
  timeEnd,
  timeCourseStart,
  timeCourseEnd,
  course,
  variant,
  lang,
  defaultLabel,
  defaultColor,
  defaultPriority = 999999999,
  showLiveBadge,
  showLiveCountdown,
  useTagBadge = false,
  className = '',
  showBadgeVC = false,
}: BottomLabelLiveProps) => {
  const [nowInterval, setNowInterval] = useState(1000);
  const now = useTime(nowInterval); //not refresh every second

  const [badgeLabelText, setBadgeLabelText] = useState('');
  const [badgeColor, setBadgeColor] = useState('red');
  const [timeStartValue, setTimeStartValue] = useState(0);
  const [timeEndValue, setTimeEndValue] = useState(0);
  const [timeStartCourseValue, setTimeStartCourseValue] = useState(0);
  const [timeEndCourseValue, setTimeEndCourseValue] = useState(0);
  const [showBadgePDP, setShowBadgePDP] = useState(false);


  const userProfile: UserProfile = useSelector(state => state.user.userProfile);
  useEffect(() => {
    if (userProfile) {
      if(isNewLivestream(course)  && ( window.location.href.includes('courseDetail') || window.location.href.includes('build-your-career') || window.location.href.includes('lp') || (window.location.href.includes('profile') || window.location.href.includes('profile/upcoming')))){
        const session= course?.courseSessions?.[0];
        const timeStart = session?.sessionDates?.[0]?.timeStart;
        const timeEnd = session?.sessionDates?.[0]?.timeFinish;
        setTimeStartValue(getLocalDateFromString(timeStart, userProfile?.timezone)?.getTime());
        setTimeEndValue(getLocalDateFromString(timeEnd, userProfile?.timezone)?.getTime());
        setTimeStartCourseValue(getLocalDateFromString(timeStart, userProfile?.timezone)?.getTime());
        setTimeEndCourseValue(getLocalDateFromString(timeEnd, userProfile?.timezone)?.getTime());
      }
      //convert date according to userProfile timezone
      else{
      setTimeStartValue(getLocalDateFromString(timeStart, userProfile?.timezone)?.getTime());
      setTimeEndValue(getLocalDateFromString(timeEnd, userProfile?.timezone)?.getTime());
      if(timeCourseStart && timeCourseEnd){
      setTimeStartCourseValue(getLocalDateFromString(timeCourseStart, userProfile?.timezone)?.getTime());
      setTimeEndCourseValue(getLocalDateFromString(timeCourseEnd, userProfile?.timezone)?.getTime());
      }
      }
    }

  }, [timeStart, timeEnd, userProfile?.timezone, window.location.href, timeCourseStart,timeCourseEnd]);

  useEffect(() => {
    if ( window.location.href.includes('courseDetail') || window.location.href.includes('build-your-career') || window.location.href.includes('lp')){
      setShowBadgePDP(true);
    }else{
      setShowBadgePDP(false);
    }
  }, []);

  useEffect(() => {
    const timeNow = new Date().valueOf();
    const countdownTimeStart =
      showLiveCountdown || timeStartValue - timeNow < threshold15Min
        ? timeStartValue - timeNow
        : null;
        const countdownTimeStartCourse =
        showLiveCountdown || timeStartCourseValue - timeNow < threshold15Min
          ? timeStartCourseValue - timeNow
          : null;  
    // const countdownTimeEnd =
    //   (showLiveCountdown || timeEndValue - timeNow < threshold15Min) ? timeEndValue - timeNow : null;

    //if live is not started --> refresh at live starting - 15 minutes
    //if live is already started (finished) --> not refresh
    // const interval = timeStartValue - new Date().getTime();
    // let newNowInterval = interval > 0 ? interval - threshold15Min : null;
    // newNowInterval = newNowInterval && newNowInterval < 1000 ? 1000 : newNowInterval;
    let newNowInterval = null;
    let newBadgeColor = 'defaultColor';

    //1. DISABILITATO MOMENTANEAMENTE PER EVOLUTION 28/07/22 - recording available
    // if (BADGE_PRIORITY[BADGE.recording_available] <= defaultPriority &&
    //   defaultPriority && !isCompletedCourse(course) && getLivestreamRecording(course, userProfile)
    // ) {
    //   setBadgeLabelText(variant === 'medium' ? lang.RECORDING_AVAILABLE_ABBR : lang.RECORDING_AVAILABLE);
    // }
    //2. DISABILITATO MOMENTANEAMENTE PER EVOLUTION 28/07/22 - recording available soon
    // else if (BADGE_PRIORITY[BADGE.recording_available_soon] <= defaultPriority &&
    //   !isCompletedCourse(course) && willLivestreamRecorded(course, userProfile)
    // ) {
    //   setBadgeLabelText(lang.RECORDING_SOON_AVAILABLE);
    // }
    //else
    //8. live now
    if ((((timeStart && timeEnd && !isNewLivestream(course)) || isNewLivestream(course)) && !showBadgePDP &&
      BADGE_PRIORITY[BADGE.livenow] <= defaultPriority &&
      timeEndValue - timeNow > 0 &&
      timeStartValue - timeNow < 0
    ) || ((showBadgePDP || (window.location.href.includes('profile') && !window.location.href.includes('profile/upcoming'))) && BADGE_PRIORITY[BADGE.livenow] <= defaultPriority &&
      timeEndCourseValue - timeNow > 0 &&
      timeStartCourseValue - timeNow < 0 )) {
      if (showBadgeVC) {
        setBadgeLabelText(lang.LIVE_VC);
      } else {
        setBadgeLabelText('• ' + lang.LIVESTREAM_NOW);
      }
      //refresh at live ending
      newNowInterval = timeEndValue - new Date().getTime();
      newBadgeColor = 'red';
      if (isAnEvent(course)) {
        newBadgeColor = 'gray';
      }
    }
    else if (((showBadgePDP || (window.location.href.includes('profile') && !window.location.href.includes('profile/upcoming'))) && BADGE_PRIORITY[BADGE.livenow] <= defaultPriority &&
      timeEndCourseValue - timeNow > 0 &&
      timeStartCourseValue - timeNow < 0 )) {
      if (showBadgeVC) {
        setBadgeLabelText(lang.LIVE_VC);
      } else {
        setBadgeLabelText('• ' + lang.LIVESTREAM_NOW);
      }
      //refresh at live ending
      newNowInterval = timeEndCourseValue - new Date().getTime();
      newBadgeColor = 'red';
      if (isAnEvent(course)) {
        newBadgeColor = 'gray';
      }
    }
    //9. live in xx
    else if ((((timeStart && timeEnd && !isNewLivestream(course)) || isNewLivestream(course))&& !showBadgePDP &&
      BADGE_PRIORITY[BADGE.live_in_x] <= defaultPriority &&
      countdownTimeStart &&
      countdownTimeStart > 0 
    )) {

      if(showBadgeVC){
        setBadgeLabelText(lang.LIVESTREAM_IN.replace('{TIME}', formatTimeForVC(countdownTimeStart) + ' ' + lang?.DURATION_MIN_LABEL))
      } else {
        setBadgeLabelText(lang.LIVESTREAM_IN.replace('{TIME}', formatTime(countdownTimeStart)));
      }
      //refresh every second
      newNowInterval = 1000;
    }
    else if (((showBadgePDP || (window.location.href.includes('profile') && !window.location.href.includes('profile/upcoming'))) &&   BADGE_PRIORITY[BADGE.live_in_x] <= defaultPriority && //badge pdp
      countdownTimeStartCourse &&
      countdownTimeStartCourse > 0 )) {
      if(showBadgeVC){
        setBadgeLabelText(lang.LIVESTREAM_IN.replace('{TIME}', formatTimeForVC(countdownTimeStartCourse) + ' ' + lang?.DURATION_MIN_LABEL))
      } else {
        setBadgeLabelText(lang.LIVESTREAM_IN.replace('{TIME}', formatTime(countdownTimeStartCourse)));
      }
      //refresh every second
      newNowInterval = 1000;
    }
    //default badge
    else {
      setBadgeLabelText(defaultLabel);
      newBadgeColor = defaultColor;
    }
    setNowInterval(newNowInterval);
    setBadgeColor(newBadgeColor);
  }, [now, course, timeStartValue, timeEndValue, showLiveBadge, showLiveCountdown]);

  return (
     (true &&((!isNewLivestream(course) && ((badgeLabelText && timeStart && timeEnd && isBookedCourse(course)) || (badgeLabelText && isBookedCourse(course) && !useTagBadge ))) //booked case
     ||
     (badgeLabelText && !isBookedCourse(course)) || (badgeLabelText && isNewLivestream(course) && isNewLivestreamLiveNow(course, course.courseSessions?.[0], userProfile))//other badge like missed isNewLivestreamInTheFuture(course, livestreamSession, userProfile)
     || (badgeLabelText && ((showBadgePDP && !isNewLivestream(course)) || (window.location.href.includes('profile') && !isNewLivestream(course) && !window.location.href.includes('profile/upcoming')))))) && //live and live in badge
    (useTagBadge ? (
      <TagBadge
        variant="white-border"
        className={className + ' ' + badgeColor}
        label={badgeLabelText}
      />
    ) : (
      <BadgeCourse
        className={className}
        label={badgeLabelText}
        color={badgeColor}
        variant={variant}
      />
    ))
  );
};

export default memo(BadgeLabelLive);
