import { useState, useEffect } from 'react';
import { useSelector } from './redux';
import { HeroBannerInfoResponse, RowTeasers } from '@model/CMSHeroBanner';
import { getIdsFromTeasersHeroBanner } from '../store/selectors/coursesSelectors';
import useFetchL1 from './useFetchL1';
import { useIsEnabledForCollections } from './collections/useIsEnabledForCollections';
import { getNovelties } from '@redux-actions/';
import { useDispatch } from 'react-redux';

export class HPCarouselCourseIdL1 {
  ids: { id: string; l1: boolean; position: number, contentId: number }[] = [];
  l1Ids: {l1Id: string, contentId: number}[] = [];
}
export class HPCarouselImage {
  data: string;
  subjectTaxonomy: { externalReference: string }[] = [];
  contentId?: number;
}
export class HPCarouselCollections {
  release: number;
  brand: string;
  order: number;
  image: string;
  collectionContentId?: number;
}
export class HPCarousel {
  layout: CMSCarouselLayout = CMSCarouselLayout.EDITORIAL;
  elementNumber: number;
  courseIdAndL1: HPCarouselCourseIdL1 = new HPCarouselCourseIdL1();
  title: string;
  description: string;
  link: string;
  linkLabel: string;
  id: string;
  variant: string = 'card-evolution';
  images: HPCarouselImage[] = [];
  placeholder: CMSCarouselPlaceholder;
  chosenCourseId: string;
  placements: RowTeasers[] = [];
  collections: HPCarouselCollections[] = [];
  contentId: number;
  position: number;
}

export enum CMSCarouselLayout {
  BANNER_PROGRAM = 'Learning Path or Lesson Banner', //A1
  LATERAL_DESCRIPTION = 'Lateral Description', //A2
  BROWSE_BY_TOPIC = 'Browse By Topic', //A3
  EDITORIAL = 'Standard Carousel', //A4
  PROGRAMS = 'Certificate Programs', //A5
  TO_BE_CONTINUED = 'To Be Continued', //A6
  TRAINING_PILLS = 'New To Your Store', //A7
  COLLECTION = 'Latest Collection Carousel', //A8
  MASTER = 'Master Carousel', //A9
  BANNER_TORTONA = 'Tortona Banner', //A10
  BANNER_HARVARD_SPARK = 'Harvard Spark', // A11
  BANNER_HARVARD_MENTOR = 'Harvard Mentor', // A12
  BANNER_HARVARD_MENTORS = 'Harvard Mentors', // A12
  BOUTIQUE = 'CarouselBoutique', //A13
}

export enum CMSCarouselPlaceholder {
  MORE_PREMIUM_CONTENT = 'more-premium-content',
  PREMIUM_CONTENT = 'premium-content',
  TO_DO = 'to-do',
  PURCHASED_CONTENT = 'your-purchased-content',
  TO_BE_CONTINUED = 'to-be-continued',
  LIVE_SESSIONS = 'live-event',
  //NEW_TO_YOUR_STORE is not used for now by FE because the skin CMSCarouselLayout.TRAINING_PILLS forcely has New to your store TPs
  //it's not possible having placeholder NEW_TO_YOUR_STORE with a skin different than CMSCarouselLayout.TRAINING_PILLS
  NEW_TO_YOUR_STORE = 'new-to-your-store',
}

export const RELEASE_CMS_PREFIX = 'leonardo-release-';

const useHomepageCarousels = (visibleCarousels?: HPCarousel[]): HPCarousel[] => {
  const dispatch = useDispatch();
  const heroBannerInfo: HeroBannerInfoResponse['data'] = useSelector(
    state => state.cms.heroBannerInfo
  );

  const [hpCarousels, setHpCarousels] = useState<HPCarousel[]>([]);
  const [l1Ids, setL1Ids] = useState<string[]>([]);
  useFetchL1(l1Ids);

  useEffect(() => {
    let orderedVisibleCarouselsTemp: HPCarousel[] = [];
    const uniqueCarousels = Array.from(new Set(visibleCarousels));
    orderedVisibleCarouselsTemp = uniqueCarousels.sort((a, b) => +a.id.replace("row", '') - +b.id.replace("row", ''))
    orderedVisibleCarouselsTemp.map((carousel) => {
      if (hpCarousels.includes(carousel)) {
        carousel.position = orderedVisibleCarouselsTemp.indexOf(carousel);
      }
    });
  }, [visibleCarousels])


  useEffect(() => {
    let hpCarouselsTemp: HPCarousel[] = [];
    let l1IdsTemp: string[] = [];

    const placements = heroBannerInfo?.[0]?.placements || {};

    const rowsKey = Object.keys(placements).sort((a, b) => {
      const aIndex = +a.replace('row', '');
      const bIndex = +b.replace('row', '');
      return aIndex - bIndex;
    });
    for (let rowKey of rowsKey) {
      if (rowKey === 'row1') {
        //row1 is dedicated for hero banner
        continue;
      }

      const placement = placements[rowKey];
      if (placement?.length > 0) {
        const carousel = new HPCarousel();
        carousel.placements = placement;
        //use editorial layout as fallback
        carousel.layout =
          placement[0]?.extendedFields?.layout?.[0]?.layout ||
          placement[0]?.extendedFields?.layoutVariant?.[0]?.layout ||
          CMSCarouselLayout.EDITORIAL; //TO FIX
        carousel.elementNumber = +placement[0]?.extendedFields?.ElementNumber?.[0]?.value;
        carousel.courseIdAndL1 = getIdsFromTeasersHeroBanner(
          placement[0]?.extendedFields?.CourseIdL1
        );
        carousel.title = placement[0]?.title;
        carousel.description = placement[0]?.description;
        carousel.link = placement[0]?.link;
        carousel.linkLabel = placement[0]?.linkLabel;
        carousel.id = rowKey;
        carousel.placeholder = placement[0]?.extendedFields?.Tipology?.[0]?.externalReference;
        // carousel.placeholder = CMSCarouselPlaceholder.LIVE_SESSIONS;
        carousel.images = placement[0]?.extendedFields?.image;
        carousel.contentId = placement[0]?.contentId;
        carousel.position = 0;

        switch (carousel.layout) {
          case CMSCarouselLayout.PROGRAMS:
            carousel.variant = 'card-educational-paths-section';
            break;
          case CMSCarouselLayout.TO_BE_CONTINUED:
            carousel.variant = 'card-large-evolution';
            break;
          case CMSCarouselLayout.COLLECTION:
            carousel.collections =
              placement[0]?.extendedFields?.image?.map((image, index) => {
                //get release and convert it in positive number
                let releaseString = image.subjectTaxonomy
                  ?.find(subjectTaxonomy =>
                    subjectTaxonomy.externalReference?.startsWith(RELEASE_CMS_PREFIX)
                  )
                  ?.externalReference?.replace(RELEASE_CMS_PREFIX, '');
                let release = Math.abs(+releaseString);
                const brand = image.subjectTaxonomy
                  ?.find(subjectTaxonomy =>
                    subjectTaxonomy.externalReference?.startsWith('university-')
                  )
                  ?.externalReference?.replace('university-', '');
                // const brandContentId = brand && image.subjectTaxonomy.find(subjectTaxonomy =>
                //   subjectTaxonomy.externalReference?.startsWith('university-')).contentId
                return {
                  release,
                  brand,
                  //brandContentId: brandContentId,
                  order: index,
                  image: image.data,
                  collectionContentId: image.contentId
                };
              }) || [];
            //filter out collections with no brand or release
            carousel.collections = carousel.collections?.filter(
              a => (!!a.release || a.release === 0) && !!a.brand
            );
            break;
        }

        hpCarouselsTemp.push(carousel);

        carousel.courseIdAndL1?.l1Ids?.forEach(l1Id => {
          if (!l1IdsTemp.includes(l1Id.l1Id)) {
            l1IdsTemp.push(l1Id.l1Id);
          }
        });
      }
    }

    // console.log("useHomepageCarousels", { hpCarouselsTemp, l1IdsTemp })
    setHpCarousels(hpCarouselsTemp);
    setL1Ids(l1IdsTemp);
  }, [heroBannerInfo]);

  const isUserEnabledForCollections: boolean = useIsEnabledForCollections();
  const latestCollectionsCMSCarouselInfo = useSelector(
    state => state.trainingPills.latestCollectionsCMSCarouselInfo
  );
  const userProfile = useSelector(state => state.user.userProfile);
  useEffect(() => {
    //download the latest colletions carousel if not already done or the user language has changed
    if (
      isUserEnabledForCollections &&
      userProfile &&
      latestCollectionsCMSCarouselInfo?.language !== userProfile?.preferredLang &&
      !latestCollectionsCMSCarouselInfo?.isLoading &&
      hpCarousels
    ) {
      let collections = [];
      hpCarousels.forEach(carousel => {
        collections.push(...carousel.collections);
      });

      if (collections?.length > 0) {
        dispatch(
          getNovelties({
            language: userProfile.preferredLang,
            variant: 'carousel-cms',
            collections: collections,
          })
        );
      }
    }
  }, [isUserEnabledForCollections, userProfile?.preferredLang, hpCarousels]);
  //console.log(hpCarousels)
  return hpCarousels;
};

export default useHomepageCarousels;
