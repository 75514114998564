import React from 'react';
import CommunityIcon from '@svg-icons/manager-assignment/Community.svg';
import ArrowIcon from '@svg-icons/manager-assignment/ArrowRound.svg';
import BellIcon from '@svg-icons/manager-assignment/Notification.svg';

/**
 * Function to return if user is a manager
 * @param role 
 * @returns true if the user has the role manager
 */
export const isUserManager = (role: string[]): boolean => {
    return role && role.includes('manager')
}

export const getTutorialAssignmentBoxesItems = (lang: any): {icon: React.ReactNode, text: string}[] => {

    const tutorialAssignmentBoxesItems = [
        { icon: <CommunityIcon /> , text: lang.MANAGER_ASSIGNMENT_BOX_1 },
        { icon: <ArrowIcon />, text: lang.MANAGER_ASSIGNMENT_BOX_2 },
        { icon: <BellIcon />, text: lang.MANAGER_ASSIGNMENT_BOX_3 },
    ];

    return tutorialAssignmentBoxesItems

}