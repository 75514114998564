import React from "react";
import CardCover from "./CardCover";
import { handleOnEnterKeyPress, printDuration } from "@utility/Api";
import { useSelector } from "@hooks/redux";
import usePreview from "@hooks/usePreview";
import CartIcon from "@components/CartIcon";
import { CardDisplayProps } from "./CardDisplayUtils";
import { isPurchasable } from "@utility/ecommerceUtility";
import WishlistIcon from "@components/WishlistIcon";
import AnimationExpand from "@components/animations/AnimationExpand";
import DetailsSingleActivityButton from "@components/DetailsSingleActivityButton";
import { BadgeCourseClass } from "@utility/CourseUtility";
import RibbonCourseEvolution from "@components/UI/RibbonCourseEvolution";
import { cmMetaData } from "@utility/previewUtils";

type CardLargeEvolutionProps = CardDisplayProps & {
  tag: BadgeCourseClass;
  postAction?: () => void;
};;

const CardLargeEvolution = (props: CardLargeEvolutionProps) => {
  const {
    tag = new BadgeCourseClass(),
  } = props;

  const lang = useSelector((state) => state.utils.lang);

  const isLoadingDetails = useSelector((state) => state.course.isLoadingDetails);

  const [
    { },
    showPreview,
    setShowPreview,
    { },
    { },
    { },
    { },
    { },
    previewFallback,
    onVideoPreviewError,
  ] = usePreview();

  const showPreviewOnHover = (show) => {
    if (props.previewVideoUrl) {
      setShowPreview(show);
    }
  };

  const handleClickCard = () => {
    props.openCourseModal();
  };

  const handleKeyDown = (event) => {
    handleOnEnterKeyPress(event, handleClickCard, true);
  };

  const totalLearningPathDuration = props.course.totalLearningPathDuration 
  ? (props.course.totalLearningPathDuration !== 0 
    ? props.course.totalLearningPathDuration 
    : props.course.duration) 
  : props.course.duration;

  return (
    <a
      data-id={props.courseIdMaster}
      data-element-id={props.dataElementId}
      aria-label={lang?.SHOW_OVERVIEW_ITEM?.replace("{name}", props.courseFullName)}
      className={
        "card-large-evolution hide-highlight-color" +
        (showPreview ? " card-v3__showing-preview" : "")
      }
      onClick={handleClickCard}
      onKeyDown={handleKeyDown}
      tabIndex={props.tabIndex}
      onMouseEnter={() => showPreviewOnHover(true)}
      onMouseLeave={() => showPreviewOnHover(false)}
      {...props.course?.contentId && cmMetaData(props.course?.contentId)}
    >
      <div className="card-large-evolution__image">
        <CardCover
          image={props.image}
          courseFullName={props.courseFullName}
          fallbackImage={props.fallbackImage}
          variant="card-large-evolution"
          hideShadow
          typeHarvard={props?.typeHarvard}
        />
        {!!props.valueBar && (
          <div className="card-large-evolution__progress">
            <AnimationExpand
              key={props.valueBar}
              initialWidth="0"
              finalWidth={props.valueBar + "%"}
              animationEase="easeOut"
            ></AnimationExpand>
          </div>
        )}
        {showPreview && props.previewVideoUrl && !previewFallback && (
          <video
            autoPlay
            loop
            muted
            className="card-v3__video"
            src={props.previewVideoUrl}
            webkit-playsinline="true"
            playsInline
            aria-label={props.courseFullName + " video"}
            aria-hidden="true"
            onError={onVideoPreviewError}
          />
        )}

        <div className="card-large-evolution__play-btn">
          <DetailsSingleActivityButton
            lang={lang}
            course={props.course}
            subtitle={null}
            delayGetCourseDetails
            isLoading={isLoadingDetails[props.courseIdMaster]}
            ctaCard
            data-element-id={props.dataElementId}
            postAction={props.postAction}
          />
        </div>
        {/* {canCourseBeAccessed(course) && isVideoCourse(course) &&
          <>
            {valueBar > 0 ? (
              <ButtonV2
                className="card-large-evolution__play-btn black no-icon"
                variant="play-btn"
                handleClick={() => {}}
              >
                {lang.CONTINUE_LABEL}
              </ButtonV2>
            ) : (
              <ButtonV2
                className="card-large-evolution__play-btn black"
                variant="play-btn"
                handleClick={() => {}}
                children={null}
              />
            )}        
          </>
        } */}
        <div className="card-large-evolution__icons">
          {isPurchasable(props.course) && (
            <div className="icon cart">
              <CartIcon variant="course" course={props.course} />
            </div>
          )}

          <WishlistIcon
            isWishlist={props.isWishlist}
            isLoadingWishlistLocal={props.isLoadingWishlistLocal}
            isNotYetAvailable={props.isNotYetAvailable}
            enableWishlist={props.enableWishlist}
            toggleWishlist={props.toggleWishlist}
            isStatic={props.isStatic}
          />
        </div>

        <div className="card-large-evolution__content">
          <div className="card-large-evolution__content-wrapper">
            <p className="card-large-evolution__type">{props.ctypeName || "​"}</p>
            <div className="flex justify-between align-center">
              {props.courseFullName && <p className={"card-large-evolution__name"}>{props.courseFullName}</p>}
            </div>

            <div className="card-large-evolution__footer">
              <RibbonCourseEvolution
                course={props.course}
                className="card-large-evolution__tag"
                useBadgeLabelLive={props.useBadgeLabelLive}
                ribbonInfo={tag}
              />
              {!!totalLearningPathDuration &&
                <span className="card-large-evolution__duration">
                  {printDuration(totalLearningPathDuration, lang, false, false, props.course)}
                </span>
              }
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default CardLargeEvolution;
