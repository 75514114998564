import React from "react";
import {LangMap} from "@model/CoursesClass";

enum EStateCell{
	DISABLED    = 'cell--disabled',
	FOCUS       = 'cell--focused',
	HIGHLIGHTED = 'cell--highlighted',
	SELECTED    = 'cell--selected',
}

enum EStateHCell{
	DISABLED    = 'hcell--disabled',
	FOCUS       = 'hcell--focused',
	HIGHLIGHTED = 'hcell--highlighted',
	SELECTED    = 'hcell--selected',
}

enum ESortType{
	NONE = 0,
	ASC = 1,
	DESC = 2,
}

enum ECellType{
	DATA,
	DEFAULT = 'cell--default',
	EMPTY = 'cell--empty',
	ICON = 'cell--icon',
	SMALL = 'cell--small',
	TAGGED = 'cell--tagged',
	LINK = 'cell--link',
	HEADER = 'hcell',
}

enum EStateRow{
	EMPTY       = 'row--empty',
	HIGHLIGHTED = 'row--highlighted',
	SELECTED    = 'row--active'
}

type TDataRow = Array<Record<string,any>>

type TCellDataHeader = {
	key: string,
	label?: string,
	labelKey?: string,
	type: ECellType,
	sortable: boolean,
	filterable: boolean,
	fixedWidth?: number,
}

type TSortProps = {
	columnKey: string,
	mode: ESortType
}

type TDataHCell ={
	readonly column:TCellDataHeader,
	readonly data: string | null,
	lang: LangMap,
	state?: EStateHCell,
	onSort?: (props: TSortProps | string)=>void
	columnSorted?: TSortProps,
	fixedWidth?: number
}

type TDataCell ={
	readonly column:TCellDataHeader,
	readonly data: any | null,
	lang: LangMap,
	state?: EStateCell,
	onClick?: ()=>void
	fixedWidth?: number,
} & {alreadyInSimulation?: boolean}

type TData = {
	header: Array<TCellDataHeader>,
	body: TDataRow
}

type TCell = {
	label: string
	column: string
	state?:EStateCell
	type?: ECellType | any[]
	fixedWidth?: number
}

type TCellIconButton = Omit<TCell, 'label'> &{
	icon: React.ReactElement | JSX.Element
	onClick?: ()=>void
}

type TCellTextAndBag = TCell & {
	tag: string
}

type TCellLink = TCell & {
	href: string
	icon?: React.ReactElement | JSX.Element
}

type TCellClickable = TCell & {
	onClick?: (any)=>void
}

type TCellHeader = TCellClickable & {
	sortable: boolean
	sort?: ESortType
	state?:EStateHCell
	onSort?: (props: TSortProps | string)=>void,
	columnSorted?: TSortProps
}

type TRow = {
	header: Array<TCellDataHeader>
	state?: EStateRow
	columnSorted?: TSortProps
	autoHideDataCell?: boolean // default true
}

type TRowHeader = TRow &{
	onSort: (props: TSortProps | string)=>void,
	onRenderHCell: (props: TDataHCell)=>void,
	selectedCol?: string
}

type TRowBody = TRow & {
	order:number
	isHighlighted?: boolean
	isSelected?: boolean
	data: Record<string,any>
	onRenderCell: (TDataCell)=>void,
	selectedCol?: string
	dataUsername?: string;
	className?: string;
}

type TRowBodyEmpty = Omit<TRowBody, "cells">

type TTable = {
	variant: "lazyloading" | "default",
	firstAndLastItemInPage?: {first: number, last: number},
	data: TData,
	nItemsPerPage?: number,
	totalNumOfItems?: number,
	showPagination: boolean,
	onRenderCell: (props: TDataCell)=>void,
	onRenderHCell: (props: TDataHCell)=>void,
	onSort: (props: TSortProps | string )=>void,
	columnSorted?: TSortProps,
	selectedRows?: TDataRow,
	highlightedRows?: TDataRow,
	useNumItemsInPagination?: boolean,
	selectedCol?: string,
	showRowEmpty?: boolean,
	customGoPrevPage?: (pageIndex?: number) => void,
	customGoNextPage?: (pageIndex?: number) => void,
	currPage?: number,
	lastPage?: number,
	isLoading?: boolean,
}

export {
	EStateCell, EStateHCell, ECellType, ESortType, EStateRow,
	TCellDataHeader, TCell, TCellIconButton, TCellTextAndBag, TCellLink, TCellHeader,
	TRowHeader, TRowBody, TRowBodyEmpty,
	TDataCell, TDataHCell, TSortProps, TData,
	TTable
}