import * as actionTypes from './actionTypes';
import * as urlList from '../../config';
import { getHeaders } from '../../utility/Api';
import { showError } from './utilsActions';
import { Course, CoursesMap } from '@model/CoursesClass';
import { DiagnosticCategory } from 'typescript';
import { isNoStandalone } from '@utility/CourseUtility';
const axios = require('axios');

const mockGetUsersToAssign = async () => {
  const usersToAssign = await fetch('/data/getUserToAssignMock.json');
  const usersToAssignJson = await usersToAssign.json();
  return { data: usersToAssignJson };
};
export const assignUsersToCourse = (result) => {
  return (dispatch) => {
    let url = urlList.POST_USERS_TO_COURSE;
    dispatch(request());

    axios
      .post(url, result)
      .then((response) => {
        dispatch(success())
      })
      .catch((err) => {
        dispatch(failure());
        dispatch(showError(err));
      });
  };

  function request() {
    return {
      type: actionTypes.POST_USERS_TO_ASSIGN_REQUESTED,
    };
  }

  function success() {
    return {
      type: actionTypes.POST_USERS_TO_ASSIGN_COMPLETED,
    };
  }

  function failure() {
    return {
      type: actionTypes.POST_USERS_TO_ASSIGN_FAILED,
    };
  }

  function showError(error) {
    return {
      payload: error,
    };
  }
};


export const fetchUsersToAssign = (courseId, setProgress, setCompleted, setUsersToAssign, setError) => {
    return (dispatch) => {
      let url = urlList.GET_USERS_TO_ASSIGN;
      dispatch(request());
  
      axios
        .get(url, { headers: getHeaders(), params: { courseId: courseId } })
        .then((response) => {
          const usersToAssign = response?.data?.userManagerAssignmentList;
          const loadingData = response?.data?.userManagerAssignmentLoader;
          const error = loadingData.error;
  
          if (loadingData) {
            setProgress(loadingData.percentage);
  
            if (loadingData.percentage == 100) {
                if(error){
                    setCompleted(true);
                    setError(loadingData.error);
                    dispatch(success([]));
                }
                else{
                    setCompleted(true);
                    setUsersToAssign(usersToAssign);
                    dispatch(success(usersToAssign));
                }
            }
          }
        })
        .catch((err) => {
          setError(err);
          setCompleted(true);
          dispatch(success([]));
          dispatch(showError(err));
        });
    };
  
    function request() {
      return {
        type: actionTypes.GET_USERS_TO_ASSIGN_REQUESTED,
      };
    }
  
    function success(usersToAssign) {
      return {
        type: actionTypes.GET_USERS_TO_ASSIGN_COMPLETED,
        payload: usersToAssign,
      };
    }
  
    function failure() {
      return {
        type: actionTypes.GET_USERS_TO_ASSIGN_FAILED,
      };
    }
  };

export const getAssignmentsAndRetakesCourseIds = (coursesMap: CoursesMap) => {
    return (dispatch) => {
        dispatch(request());
        let url = urlList.GET_COURSE_ASSIGNED;

        axios.get(url, {
            headers: getHeaders()
        }).then(response => {

            const assignmentsCourses = Object.values(coursesMap).filter(course =>
                (response?.data?.courseIdAssignments?.includes(course.courseIdMaster) || response?.data?.courseIdAssignments?.includes(course.courseId)) && !isNoStandalone(course)
            );
            const retakesCourses = Object.values(coursesMap).filter(course =>
                (response?.data?.courseIdRetakes?.includes(course.courseIdMaster) || response?.data?.courseIdRetakes?.includes(course.courseId)) && !isNoStandalone(course)
            );

            dispatch(success(assignmentsCourses, retakesCourses));

        }).catch(err => {
            dispatch(failure());
            dispatch(showError(err));
        });
    }

    function request() {
        return {
            type: actionTypes.GET_ASSIGNMENTS_AND_RETAKES_COURSE_IDS_REQUESTED
        };
    }

    function success(assignmentsCourses: Course[], retakesCourses: Course[]) {
        return {
            type: actionTypes.GET_ASSIGNMENTS_AND_RETAKES_COURSE_IDS_SUCCESS,
            assignmentsCourses,
            retakesCourses
        };
    }

    function failure() {
        return {
            type: actionTypes.GET_ASSIGNMENTS_AND_RETAKES_COURSE_IDS_FAILED
        };
    }
}