import React, { useEffect, useState } from 'react';
import ButtonV2 from '../ButtonV2';
import { useDispatch, useSelector } from 'react-redux';
import { CourseSession } from '@model/CoursesClass';
import { isBookedCourse, joinSession } from '@utility/Api';
import {
    getNewLivestreamRecording,
    getVCRecordingLinks,
    isNewLivestreamInTheFuture,
    isNewLivestreamLiveNow,
} from '@utility/VCUtility';
import * as actions from '@redux-actions/';
import { sessionStatus } from '@utility/const';
import { toggleRatingModal } from '@redux-actions/';
import { is } from 'date-fns/locale';
import PlayIcon from '../PlayIcon';
import clsx from '@utility/clsx';

const ButtonNewLivestream = ({
  course,
  play = null,
  variant = 'primary',
  children = null,
  onClick = () => {},
}) => {
  const [livestreamSession, setLivestreamSession] = useState<CourseSession>(null);
  const [directUrl, setDirectUrl] = useState<string>(null);
  const [completionUrl, setCompletionUrl] = useState<string>(null);
  const [sessionUrl, setSessionUrl] = useState<string>(null);
  const [hasClickedJoin, setHasClickedJoin] = useState<boolean>(false); // Stato per tracciare il click

  const lang = useSelector(state => state.utils.lang);
  const userProfile = useSelector(state => state.user.userProfile);
  const isBookingSession = useSelector<boolean>(state => state.course.isBookingSession);
  const dispatch = useDispatch();


  // Effettua il set del sessionUrl quando il corso è disponibile
  useEffect(() => {
    if (course?.userRelevantSessions?.[0]?.webExSessionLink) {
      setSessionUrl(course.userRelevantSessions[0].webExSessionLink);
    }
  }, [course]);

  // Effettua il set della sessione e dei link quando il corso è disponibile
  useEffect(() => {
    if (course?.courseSessions?.[0]) {
      setLivestreamSession(course.courseSessions[0]);
      const { completionurl, directurl } = getVCRecordingLinks(course, userProfile);
      setDirectUrl(directurl);
      setCompletionUrl(completionurl);
    }
  }, [course]);

  // Monitoriamo sessionUrl e apriamo la sessione se l'utente aveva cliccato il pulsante
  useEffect(() => {
    if (hasClickedJoin && sessionUrl) {
      console.log("🚀 Opening session:", sessionUrl);
      window.open(sessionUrl, "_blank"); // Apre il link in una nuova scheda
      //joinSession(course, livestreamSession, toggleRatingModal); // Dispatch dell'azione di join
      setHasClickedJoin(false); // Reset dello stato
    }
  }, [sessionUrl, hasClickedJoin]);

  const handleJoinSession = async (e) => {
    if (!livestreamSession) return;
    e?.stopPropagation();
    try {
      console.log("📌 Booking session...");
      await dispatch(actions.bookSession(livestreamSession.id, true, course.courseId));
      console.log("✅ Session booked!");

      // Impostiamo che l'utente ha cliccato il bottone, il useEffect farà il resto
      setHasClickedJoin(true);
    } catch (error) {
      console.error("⚠️ Error booking session:", error);
    }
  };

  return (
    <>
      {isNewLivestreamInTheFuture(course, livestreamSession, userProfile) && (
        <ButtonV2
          variant="primary"
          handleClick={() => dispatch(actions.createSessionIcs(course.courseIdMaster, livestreamSession.id))}
        >
          {lang.SAVE_CALENDAR}
        </ButtonV2>
      )}

      {play && ((isNewLivestreamLiveNow(course, livestreamSession, userProfile) && livestreamSession?.availablePlaces > 0 )||(isNewLivestreamLiveNow(course, livestreamSession, userProfile) && isBookedCourse(course)))&& (
        <PlayIcon
                      className={clsx(
                        'play-icon__event-progress',
                         'play-icon--card_education_path'
                      )}
                      valueBar={ 0}
                      showProgressAsBorder={false}
                      course={course}
                      onClick={e => {
                        handleJoinSession(e)
                      }}
                    />
      )}
      {!play && ((isNewLivestreamLiveNow(course, livestreamSession, userProfile) && livestreamSession?.availablePlaces > 0 )||(isNewLivestreamLiveNow(course, livestreamSession, userProfile) && isBookedCourse(course)))&& (
        <ButtonV2
          variant="primary"
          loading={isBookingSession} // Mostra lo stato di caricamento se sta prenotando
          handleClick={handleJoinSession} // Usa la funzione aggiornata
        >
          {lang.JOIN_LABEL}
        </ButtonV2>
      )}
      {!isNewLivestreamLiveNow(course, livestreamSession, userProfile) &&
        !isNewLivestreamInTheFuture(course, livestreamSession, userProfile) &&
        getNewLivestreamRecording(course, livestreamSession, userProfile) && (
          <ButtonV2
            variant="primary"
            handleClick={() => {
              dispatch(actions.toggleIframePopup(
                true,
                course,
                `${directUrl?.split('?')[0]}?ap=1&mute=1&api=1`,
                completionUrl,
                true
              ));
            }}
          >
            {lang.ACTIVITY_BUTTON_VIEW_RECORDING}
          </ButtonV2>
        )}
    </>
  );
};

export default ButtonNewLivestream;
