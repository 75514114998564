import React, { useState } from "react";
import ModalV3 from "@components/UI/Modals/ModalV3";
import { useDispatch, useSelector } from "react-redux";
import ButtonV2 from "@components/UI/ButtonV2";
import { useBreakpointBoolean } from "@hooks/createBreakpoint";
import { toggleModalV3 } from "@redux-actions/index";
import {useHistory} from 'react-router-dom';
import { USER_URLS } from "@components/link-utils";
import { queryParams } from "@utility/const";

const ModalQuitAssignment = () => {

    const lang = useSelector((state: any) => state.utils.lang);
    const modalsV3Open = useSelector((state: any) => state.utils.modalsV3Open);
    const dispatch = useDispatch()
    const history = useHistory();
    const { isDesktopOrTabletLandscape } = useBreakpointBoolean();

    const QuitButton = () => {

        const handleClick = () => {
            console.log("QUIT");
            dispatch(toggleModalV3("quitAssignment", false));
            localStorage.removeItem('userStates');
           // history.push(USER_URLS.HOMEPAGE.URL + '?' + queryParams.COURSE_DETAIL + '=' + localStorage.getItem("course"));
           history.go(-2);
           localStorage.removeItem('userToSend');
            localStorage.removeItem('usersToAssign');
            localStorage.removeItem('usersToAssignFinal');
            localStorage.removeItem('usersToReAssignFinal');
            localStorage.removeItem('selectedFilters');
            localStorage.removeItem('appliedFilters');
            localStorage.removeItem('usersFetched');
        }

        return (
            <ButtonV2 
                className="quit-assignment__button"
                handleClick={handleClick}
                small
                variant={`${isDesktopOrTabletLandscape ? 'tertiary' : 'text-btn-no-arrow'}`}
            >
                {lang.QUIT}
            </ButtonV2>
        )
    }

    const KeepAssigningButton = () => {
        const handleClick = () => {
            console.log("KEEP_ASSIGNING");
            dispatch(toggleModalV3("quitAssignment", false));
        }

        return (
            <ButtonV2 
                className="keep-assigning__button"
                handleClick={handleClick}
                small
            >
                {lang.KEEP_ASSIGNING}
            </ButtonV2>
        )
    }

    const modalButtons = [
        <QuitButton />, 
        <KeepAssigningButton />
    ]; 


    return (<>
        {modalsV3Open && modalsV3Open.quitAssignment &&
            <ModalV3
                className={"quit-assignment"}
                title={lang.MODALS_QUIT_ASSIGNMENT_TITLE}
                buttons={modalButtons}
            >
                <p className="quit-assignment__description">
                    {lang.MODALS_QUIT_ASSIGNMENT_DESCRIPTION}
                </p>
            </ModalV3>
        }
    </>
        
    );
};

export default ModalQuitAssignment;