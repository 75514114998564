import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { Props as SelectProps } from '@components/UI/Select';
import { useSelector } from '@hooks/redux';
import useLanguageSelection from '@hooks/useLanguageSelection';
import { buildClickDataTrackingObject } from '@model/TrackingClass';
import camelCase from 'lodash/camelCase';
import lang from '@utility/lang';
import { LangMap } from '@model/CoursesClass';
import { USER_URLS } from '@components/link-utils';

export type Props = Omit<SelectProps, 'isLoading' | 'options' | 'onChange' | 'value'>;

const LanguageSelect = (props: Props) => {
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);
  const { i18n, t } = useTranslation();
	const lang: LangMap = useSelector(state => state.utils.lang);
  const [languages, selectedLanguage, setLanguage] = useLanguageSelection(); //LOGGED LANGUAGES
  const NotLoggedLanguages = {
    //NOT LOGGED LANGUAGES
    en: { value: 'en', label: 'English' },
    it: { value: 'it', label: 'Italiano' },
    es: { value: 'es', label: 'Español' },
    pt_br: { value: 'pt_br', label: 'Português' },
    fr: { value: 'fr', label: 'Français' },
    //TICKET LEON-6710 REMOVING LANGUAGE FROM PRELOGIN PAGE
    //zh_cn: { value: 'zh_cn', label: '中文（简体)' },
  };

  const changeLanguage = language => {

    const preservedHistoryPath = sessionStorage.getItem('BACKBUTTONURL');
    //const preservedCourseTitle = sessionStorage.getItem('initialCourseTitle')

    isAuthenticated ? setLanguage(language) : i18n.changeLanguage(language.value);

    if (preservedHistoryPath) {
      sessionStorage.setItem('BACKBUTTONURL', preservedHistoryPath);
      //sessionStorage.setItem('initialCourseTitle', preservedCourseTitle)
    }

    // LEON-6509
    // Set current Y in order to prevent scroll up after language change in Safari
    if(window.location?.pathname ===(USER_URLS?.HOMEPAGE.URL) || window.location?.pathname?.includes("test-public")  ){
    window?.localStorage?.setItem('scrollPosition', window?.scrollY?.toString());
    window.scrollTo(0, window?.scrollY)}
  };

  return (
    <Select
      labelFixedTop={true}
      options={isAuthenticated ? languages : Object.values(NotLoggedLanguages)}
      onChange={option =>{ buildClickDataTrackingObject("footer_language",camelCase(option?.label));changeLanguage(option)}}
      value={
        isAuthenticated
          ? selectedLanguage
          : NotLoggedLanguages[i18n.language] || NotLoggedLanguages['en']
      }
      removeLeftPadding
      isSearchable
      aria-label={props.ariaLabel && props.ariaLabel}
      // placeholder={props.ariaLabel && props.ariaLabel}
      fillSpace
      {...props}
    />
  );
};

LanguageSelect.displayName = 'LanguageSelect';

export default LanguageSelect;
