import React from 'react';
import { Course, CoursesMap } from '@model/CoursesClass';
import CarouselSection from '@components/CarouselSection';
import { useSelector } from '@hooks/redux';
import useGetOnboardingRMCourses from '@hooks/onboarding/useGetOnboardingRMCourses';
import { getOnboardingC6Course, isCourseOnBoardingRetail } from '@utility/onBoardingCourseUtility';
import clsx from '@utility/clsx';
import { areAllOnboardingRMCoursesCompleted } from '@utility/Api';

enum OnboardingTabEnum {
  lessons = 'lessons',
  activities = 'activities',
}

interface RelatedMandatoryCarouselProps {
  course?: Course;
  isPDP?: boolean;
  isHomepage?: boolean;
  tab?: string;
}

const RelatedMandatoryCarousel = ({ course, isPDP = false, isHomepage = false, tab }: RelatedMandatoryCarouselProps) => {
  const lang = useSelector(state => state.utils.lang);
  const coursesMap: CoursesMap = useSelector(state => state.course.coursesMap);
  const onboardingRelatedMandatoryCourses = useGetOnboardingRMCourses();
  const filteredCourses = React.useMemo(() => {
    if (!course) return [];
    return onboardingRelatedMandatoryCourses?.filter(rmCourse => rmCourse.courseId !== course.courseId) || [];
  }, [course, onboardingRelatedMandatoryCourses]);

  const showOnboardingRMCarousel = React.useMemo(() => {
    if (isHomepage && areAllOnboardingRMCoursesCompleted(onboardingRelatedMandatoryCourses)) {
      return false;
    }
  
    const hasValidRMCourses = (courses: any[]) => courses.length > 0 && courses.some(course => course && Object.keys(course).length > 0);

    if (isPDP && course) {
      return hasValidRMCourses(filteredCourses);
    } else {
      const hasOnboardingRMCourses = hasValidRMCourses(onboardingRelatedMandatoryCourses);
      if (course) {
        return hasOnboardingRMCourses && isCourseOnBoardingRetail(course);
      } else {
        return hasOnboardingRMCourses;
      }
    }
  }, [isPDP, isHomepage, course, filteredCourses, onboardingRelatedMandatoryCourses]);

  const isGetCourseCompleted: boolean = useSelector(state => state.course.isGetCourseCompleted);
  const isLoadingCourses = !isGetCourseCompleted;
  const hasOnboardingC6Course = getOnboardingC6Course(coursesMap);
  const maxCoursesToShow = 1000; //high number to avoid showing SEE ALL/VIEW ALL ctas

  return (
    <>
      {/* Onboarding Related Mandatory Content Carousel */}
      {showOnboardingRMCarousel && !!hasOnboardingC6Course && ( 
          <div className={clsx('onboarding-related-mandatory-carousel', {
            'onboarding-related-mandatory-carousel--lessons': (tab === OnboardingTabEnum.lessons || tab === OnboardingTabEnum.activities),
          })} 
          id='related-mandatory-content_carousel'>
          <div className='onboarding-related-mandatory-carousel-wrapper'>
            <CarouselSection
              courses={isPDP ? filteredCourses : onboardingRelatedMandatoryCourses}
              lang={lang}
              title="ONBOARDING_RELATED_MANDATORY_CAROUSEL_TITLE"
              description="ONBOARDING_RELATED_MANDATORY_CAROUSEL_DESCRIPTION"
              classNames="carousel-max-width"
              variableWidth
              isLoading={isLoadingCourses}
              numCoursesToShow={maxCoursesToShow}
              />
            </div>
          </div>
      )}
    </>
  );
};

export default RelatedMandatoryCarousel;
