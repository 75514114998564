import React, { FC } from "react";
import { ModalV3ButtonProps, ModalV3Props } from "src/types/UI/Modals/ModalV3";


const ModalV3: FC<ModalV3Props> = ({
    className,
    title,
    children,
    buttons,
}) => {

    return (
        <div className={`modal-v3 ${className}`}>
            <div className={`modal-v3__container ${className}`}>
                {/* TITLE SECTION */}
                {title && <span className="modal-v3__title">{title}</span>}
                {/* CHILDREN SECTION (WHAT STANDS IN THE MIDDLE OF THE MODAL) */}
                {children && children}
                {/* BUTTONS SECTION */}
                {buttons &&
                    <div className="modal-v3__buttons">
                        {buttons.map((button: ModalV3ButtonProps, index: number) =>
                            <React.Fragment key={index}>{button}</React.Fragment>)
                        }
                    </div>
                }
            </div>
        </div>
    );
};

export default ModalV3;