import { Course, LangMap } from '@model/CoursesClass';
import { UserProfile } from '@model/User';
import {
  willCourseBeRemovedSoon,
  handleOnEnterKeyPress,
  printDate,
  printDateEvents,
  hadMultipleSessions,
  isVirtualClassroom,
  isLivestreamCourse,
  isNewLivestream,
} from '@utility/Api';
import clsx from '@utility/clsx';
import { isPriceable } from '@utility/ecommerceUtility';
import moment from 'moment';
import React from 'react';
import InfoIcon from '@images/svg-icons/info.svg';
import { useSelector } from '@hooks/redux';
import { getLivestreamRecording, getNewLivestreamDate } from '@utility/LivestreamUtility';
import { getVCRecording, getVCRecordingLinks } from '@utility/VCUtility';
import BottomLabelLive from '@components/CardV3/BottomLabelLive';

interface Props {
  course?: Course;
  userProfile?: UserProfile;
  lang?: LangMap;
  handleExpiringClick?: (course: Course) => void;
}

const ExpiredDateOrLabel = ({
  course,
  userProfile,
  lang,
  handleExpiringClick,
}: Props): JSX.Element => {
  const date = course?.endDate;
  const today = new Date();
  const dayDifference = moment(new Date(date)).diff(today, 'days');
  const ecommerceEnable = useSelector(state => state.user.ecommerceEnable);
  const {directurl} = getVCRecordingLinks(course, userProfile)
  const recording = directurl 
    || getLivestreamRecording(course, userProfile)?.length > 0;

  return (
    <>
      {course.endDate &&
        !isLivestreamCourse(course) && !isNewLivestream(course) &&
        (isPriceable(course) || !course.expectedCompletion) &&
        dayDifference > 31 &&
        !course.isPastEvent && (
          <span
            className={clsx('text-expiring', {
              clickable: isPriceable(course),
              'expiring-soon-1m': willCourseBeRemovedSoon(course, userProfile),
              'expiring-soon-7d': willCourseBeRemovedSoon(course, userProfile, '7d'),
            })}
            tabIndex={isPriceable(course) ? 0 : undefined}
            onClick={() => handleExpiringClick(course)}
            onKeyDown={event => {
              handleOnEnterKeyPress(event, () => handleExpiringClick(course), true);
            }}
          >
            {!isVirtualClassroom(course) ? (
              ''
            ) : (
              <>
                {hadMultipleSessions(course)
                  ? printDateEvents(
                      course.courseSessions[0].minStartDate,
                      course.courseSessions[course.courseSessions.length - 1].minStartDate,
                      lang
                    )
                  : //printDate(course.endDate, lang)
                    printDate(course?.courseSessions[0]?.minStartDate, lang)}
              </>
            )}
            {isPriceable(course) && (
              <div className="flex align-center">
                {lang?.EXPIRING_PRICEABLE_LABEL + ' ' + printDate(course?.endDate, lang)}
                <InfoIcon className="text-expiring-info-icon" />
              </div>
            )}
          </span>
        )}

      {dayDifference <= 31 &&
        dayDifference >= 0 &&
        !isVirtualClassroom(course) &&
        !isLivestreamCourse(course) &&
        !course.isPastEvent &&
        !isPriceable(course) && (
          <div className="text-expiring">
            {lang.LESSON_WILL_EXPIRE?.replace('{NUMBER}', dayDifference.toString())}
          </div>
        )}

      {dayDifference <= 31 &&
        dayDifference >= 0 &&
        !isVirtualClassroom(course) &&
        !isLivestreamCourse(course) &&
        !course.isPastEvent &&
        isPriceable(course) && (
          <div
            className={clsx('text-expiring', {
              clickable: isPriceable(course),
              'expiring-soon-1m': willCourseBeRemovedSoon(course, userProfile),
              'expiring-soon-7d': willCourseBeRemovedSoon(course, userProfile, '7d'),
            })}
            onClick={() => handleExpiringClick(course)}
            onKeyDown={event => {
              handleOnEnterKeyPress(event, () => handleExpiringClick(course), true);
            }}
          >
            <span
              className={
                ecommerceEnable &&
                ((dayDifference > 7 && dayDifference <= 31 && 'expiring-soon-1m') ||
                  (dayDifference >= 0 && dayDifference <= 7 && 'expiring-soon-7d'))
              }
            >
              {lang.EXPIRING_PRICEABLE_LABEL + ' ' + printDate(course?.endDate, lang)}
            </span>
            <InfoIcon className="text-expiring-info-icon" />
          </div>
        )}

      {dayDifference <= 31 &&
        dayDifference >= 0 &&
        isVirtualClassroom(course) &&
        !course.isPastEvent && (
          <div className="text-expiring">
            {hadMultipleSessions(course)
              ? printDateEvents(
                  course.courseSessions[0].minStartDate,
                  course.courseSessions[course.courseSessions.length - 1].minStartDate,
                  lang
                )
              : printDate(course.courseSessions[0].minStartDate, lang)}
          </div>
        )}

      {isNewLivestream(course) && (
                            <div className="ctype-container">
                          <BottomLabelLive
                            {...getNewLivestreamDate(course)}
                            variant="overview"
                            lang={lang}
                            showDate
                            showLiveBadge={course.showLiveBadge}
                            showLiveCountdown={course.showLiveCountdown}
                            isNewLivestream={true}
                          />
                          </div>
                        )}
      {(isVirtualClassroom(course) || isLivestreamCourse(course)) &&
        course.isPastEvent &&
        course?.endDate &&
        recording && (
          <div className="text-expiring">
            {lang.RECORDING_AVAILABLE_UNTIL + ' ' + printDate(course?.endDate, lang)}
          </div>
        )}

      {/* {course.endDate && isLivestreamCourse(course) && course.isPastEvent && dayDifference > 31 &&
           <div className="text-expiring">
            {lang.RECORDING_AVAILABLE_UNTIL + ' ' + printDate(course?.endDate, lang)} 
          </div>
        }    */}
    </>
  );
};

export default ExpiredDateOrLabel;
