import { Course, CoursesMap, RecordingUrl } from '@model/CoursesClass';
import { UserProfile } from '@model/User';
import { addScaleCorrection } from 'framer-motion';
import {
  compareDates,
  getParentLPPurchasable,
  getSessionByCoursesMap,
  isAnEvent,
  isLivestreamCourse,
  isNewLivestream,
  isVirtualClassroom,
} from './Api';
import { getLocalDateFromString } from './CalendarUtility';
import { getVCDateBadgeFromAssociatedSession, getVCDateForBadge } from './VCUtility';
import { useSelector } from 'react-redux';
import { getAllLpCoursesId } from './LPUtility';
import { getParentCourse } from './Api';
import { compareTime } from './localStorage';

export const getLivestreamUrls = (
  course: Course
): { liveUrl: string; liveUrlCn: string; liveChat: string; liveEventId: string } => {
  if (!course || !isLivestreamCourse(course) || !course.liveInfo || course.liveInfo.length === 0) {
    return { liveUrl: null, liveUrlCn: null, liveChat: null, liveEventId: null };
  }

  return {
    liveUrl: course.liveInfo[0].liveUrlLink,
    liveUrlCn: course.liveInfo[0].liveUrlCn,
    liveChat: course.liveInfo[0].liveChatLink,
    liveEventId: course.liveInfo[0].vimeoLiveEventId,
  };
};

export const getLivestreamDate = (course: Course): { timeStart: string; timeEnd: string } => {
  if (!course || !isLivestreamCourse(course) || !course.liveInfo || course.liveInfo.length === 0) {
    return { timeStart: null, timeEnd: null };
  }

  return {
    timeStart: course.liveInfo[0].timeStart,
    timeEnd: course.liveInfo[0].timeEnd,
  };
};

export const getNewLivestreamDate = (course: Course): { timeStart: string; timeEnd: string } => {
  if (!course || !isNewLivestream(course)) {
    return { timeStart: null, timeEnd: null };
  }
  return {
    timeStart: course.courseSessions?.[0]?.sessionDates?.[0]?.timeStart,
    timeEnd: course.courseSessions?.[0]?.sessionDates?.[0]?.timeFinish,
  };
};


export const getLivestreamDateForCourse = (course: Course): { timeCourseStart: string; timeCourseEnd: string } => {
  if (!course || !isLivestreamCourse(course) || !course.liveInfo || course.liveInfo.length === 0) {
    return { timeCourseStart: null, timeCourseEnd: null };
  }

  return {
    timeCourseStart: course.liveInfo[0].timeStart,
    timeCourseEnd: course.liveInfo[0].timeEnd,
  };
};

export const isLivestreamNotYetFinished = (
  course: Course,
  userProfile: UserProfile,
  dateToCompare = new Date()
): boolean => {
  if (!course || !userProfile || !isLivestreamCourse(course)) {
    return false;
  }

  const livestreamDate = getLivestreamDate(course);
  const livestreamEndDate = getLocalDateFromString(livestreamDate.timeEnd, userProfile?.timezone);
  //cannot join livestream if it's finished
  return dateToCompare <= livestreamEndDate;
};

export const isLivestreamFinished = (course: Course, userProfile: UserProfile): boolean => {
  if (!course || !userProfile || !isLivestreamCourse(course)) {
    return false;
  }

  return !isLivestreamNotYetFinished(course, userProfile);
};

export const isLivestreamLiveNow = (course: Course, userProfile: UserProfile): boolean => {
  if (!course || !userProfile || !isLivestreamCourse(course)) {
    return false;
  }

  const livestreamDate = getLivestreamDate(course);
  const livestreamStartDate = getLocalDateFromString(
    livestreamDate.timeStart,
    userProfile?.timezone
  );
  const livestreamEndDate = getLocalDateFromString(livestreamDate.timeEnd, userProfile?.timezone);
  const nowDate = new Date();

  //cannot join livestream if it's finished
  return nowDate >= livestreamStartDate && nowDate <= livestreamEndDate;
};

export const getLivestreamRecording = (
  course: Course,
  userProfile: UserProfile,
  moduleId: string = null
): string[] => {
  if (!course || !isLivestreamCourse(course)) {
    return null;
  }
  const { courseStates } = course;
  let recordings = [];
  if (course.liveInfo?.[0]?.recordingUrls[0]?.directUrl) {
    for (let i = 0; i < course.liveInfo?.[0]?.recordingUrls?.length; i++) {
      recordings[i] = course?.liveInfo?.[0]?.recordingUrls?.[i]?.directUrl;
      //get a particular moduleId (normally a livestream should contain just one liveInfo so it should be useless)
      if (moduleId) {
        recordings[i] = course.liveInfo?.filter(a => a.moduleId === moduleId)?.[0]?.recordingUrls?.[
          i
        ]?.directUrl;
      }
    }

    if (recordings?.length > 0 && isLivestreamFinished(course, userProfile)) {
      return recordings;
    }
  }
  return [];
};

export let getLivestreamCompletation = (
  course: Course,
  userProfile: UserProfile,
  moduleId: string = null,
  recording: string
): string => {
  if (!course || !isLivestreamCourse(course)) {
    return null;
  }
  const { courseStates } = course;
  let completionUrl = null;
  if (course.liveInfo?.[0]?.recordingUrls[0]) {
    course.liveInfo?.[0]?.recordingUrls?.map((element, i) => {
      if (element.directUrl === recording) {
        completionUrl = element.completionUrl;
      }
    });
  }
  return completionUrl;
};

export const willLivestreamRecorded = (course: Course, userProfile: UserProfile): boolean => {
  if (!course || !isLivestreamCourse(course)) {
    return false;
  }

  const { courseStates } = course;
  let recWillBe: boolean = !!course.liveInfo?.[0]?.recWillBe;

  if (isLivestreamFinished(course, userProfile)) {
    const recording = getLivestreamRecording(course, userProfile);
    if (recording) {
      return false;
    } else {
      return recWillBe;
    }
  }

  return false;
};

export const willLivestreamNotRecorded = (course: Course, userProfile: UserProfile): boolean => {
  if (!course || !isLivestreamCourse(course)) {
    return false;
  }

  const { courseStates } = course;
  if (isLivestreamFinished(course, userProfile)) {
    const recording = getLivestreamRecording(course, userProfile);
    if (recording) {
      return false;
    }
    return !willLivestreamRecorded(course, userProfile);
  }

  return false;
};

export const willBeNotifiedRecording = (course: Course, userProfile: UserProfile): boolean => {
  return course?.vimeoRecNotification && willLivestreamRecorded(course, userProfile);
};

export const selectPastLivestreams = state => {
  const livestreams: Course[] = state.course.livestreams;
  const userProfile = state.user.userProfile;
  return livestreams.filter(course => {
    const timeEnd = course?.liveInfo?.[0]?.timeEnd;
    return (
      new Date()?.valueOf() > getLocalDateFromString(timeEnd, userProfile?.timezone)?.getTime()
    );
  });
};

export const selectCurrentLivestreams = state => {
  const livestreams: Course[] = state.course.livestreams;
  const userProfile = state.user.userProfile;
  return livestreams.filter(course => {
    const timeEnd = course?.liveInfo?.[0]?.timeEnd;
    return (
      new Date()?.valueOf() <= getLocalDateFromString(timeEnd, userProfile?.timezone)?.getTime()
    );
  });
};

export const getDateForBadge = (
  course: Course,
  useAssociatedSession: boolean = false,
  coursesMap,
  associatedSession
): { timeStart: string; timeEnd: string } => {
  if (isLivestreamCourse(course)) {
    if (useAssociatedSession) {
      return {
        timeStart: course?.associatedSession?.timeStart,
        timeEnd: course?.associatedSession?.timeFinish,
      };
    } else {
      return getLivestreamDate(course);
    }
  } else if (isAnEvent(course)) {
    let startTime;
    let endTime;
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let childs = [];
    let first = 0;
    for (const courseChildId in coursesMap) {
      let parentLP = coursesMap[courseChildId].parentLP;
      parentLP.forEach(i => {
        if (isAnEvent(coursesMap[i.courseId]) && i.courseId === course.courseId) {
          childs.push(courseChildId);
        }
      });
    }
    childs.map(child => {
      if (coursesMap[child]?.showLiveBadge || coursesMap[child]?.showLiveCountdown) {
        startTime = coursesMap[child]?.liveInfo[0]?.timeStart;
        endTime = coursesMap[child]?.liveInfo[0]?.timeEnd;
      }
    });
    return {
      timeStart: startTime,
      timeEnd: endTime,
    };
  } else {
    return getVCDateBadgeFromAssociatedSession(course, associatedSession);
  }
};

export const getCourseDateForBadge = (
  course: Course,
  useAssociatedSession: boolean = false,
): { timeCourseStart: string; timeCourseEnd: string } => {
  if (isLivestreamCourse(course)) {
    if (useAssociatedSession) {
      return {
        timeCourseStart: course?.associatedSession?.timeStart,
        timeCourseEnd: course?.associatedSession?.timeFinish,
      };
    } else {
      return getLivestreamDateForCourse(course);
    }
  } else if (isVirtualClassroom(course)) {
    return getVCDateForBadge(course);
  }
  else{
    return {
      timeCourseStart: null,
      timeCourseEnd: null,
    };
  }
};
