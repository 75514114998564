import React, { useEffect, useMemo } from 'react';
import * as actions from '@redux-actions/';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ModalEvolution from './ModalEvolution';
import { printHTML } from '@utility/Api';
import { USER_URLS } from '@components/link-utils';
import useGetOnboardingRMCourses from '@hooks/onboarding/useGetOnboardingRMCourses';
import { setShowModalOnBoardingCompleted, setViewRelatedMandatoryClicked, toggleModal } from '@redux-actions/';
import { updateType } from '@utility/const';
import useGetOnboardingCourse from '@hooks/onboarding/useGetOnboardingCourse';
import useModalTypes from '@hooks/useModalByType';
import { getOnboardingC6Course, isCourseOnBoardingRetail } from '@utility/onBoardingCourseUtility';

const ModalRelatedMandatoryContent = (): React.ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const lang = useSelector(state => state.utils.lang);
  const showModalOnBoardingCompleted = useSelector(state => state.utils.showModalOnBoardingCompleted);
  const completedOnboardingCourseNotif = useSelector(state => state.utils.completedOnboardingCourseNotif);
  const coursesMap = useSelector(state => state.course.coursesMap);
  const notifications = useSelector(state => state.firebase.notifications);
  const onboardingCourse = getOnboardingC6Course(coursesMap);
  const onboardingRelatedMandatoryCourses = useGetOnboardingRMCourses();
  const modalTypes = useModalTypes();

  const showOnboardingRMCarousel = useMemo(() => {
    return onboardingRelatedMandatoryCourses?.length > 0 && onboardingRelatedMandatoryCourses?.some(course => course && Object.keys(course)?.length > 0);
  }, [onboardingRelatedMandatoryCourses]);

  const closeModal = () => {
    dispatch(setShowModalOnBoardingCompleted(false, completedOnboardingCourseNotif?.[0]));
    if (!completedOnboardingCourseNotif?.read) {
      dispatch(actions.toggleNotifAsRead(completedOnboardingCourseNotif));
    }
  };

  const goToRelatedMandatoryCarousel = () => {
    const redirectUrl = `${USER_URLS.ONBOARDING_COURSE.URL}/${completedOnboardingCourseNotif?.courseid}`;
    history.push(redirectUrl);
    dispatch(setViewRelatedMandatoryClicked(true));
    dispatch(setShowModalOnBoardingCompleted(false, completedOnboardingCourseNotif?.[0]));
  
    // Close notification panel if open
    closeNotificationPanel();
  
    // Mark notification as read if not already read
    markNotificationAsRead();
  };
  
  const closeNotificationPanel = () => {
    if (modalTypes.notificationsRightModal?.show) {
      modalTypes.notificationsRightModal?.toggle();
      dispatch(toggleModal(false, null, true));
    }
  };
  
  const markNotificationAsRead = () => {
    if (!completedOnboardingCourseNotif?.read) {
      dispatch(actions.toggleNotifAsRead(completedOnboardingCourseNotif));
    } 
  };

  useEffect(() => {
    if (!notifications) return;
  
    let notifCourseId = completedOnboardingCourseNotif?.courseid || onboardingCourse?.courseIdMaster || '';
  
    if (notifCourseId) {
      const onboardingNotifications = notifications?.filter(notif => notif?.notificationtype === updateType.LP_COMPLETED);
      const hasUnreadNotifications = onboardingNotifications?.some(notification => !notification.read);
  
      const timeoutId = setTimeout(() => {
        if (!showModalOnBoardingCompleted && onboardingNotifications && hasUnreadNotifications) {
          const unreadOnboardingNotification = onboardingNotifications?.find(notification => {
            const course = coursesMap[notification?.courseid];
            return !notification?.read && isCourseOnBoardingRetail(course);
          });
          // Show the modal again when the notification is to read again (after clicking on it in the notif. panel)
          if (unreadOnboardingNotification && (!completedOnboardingCourseNotif || Object.keys(completedOnboardingCourseNotif)?.length === 0)) {
            dispatch(setShowModalOnBoardingCompleted(true, unreadOnboardingNotification));
          }
        }
      }, 1000);
  
      // Cleanup function to clear the timeout
      return () => clearTimeout(timeoutId);
    }
  }, [notifications, completedOnboardingCourseNotif, onboardingCourse, showModalOnBoardingCompleted, dispatch]);
  
  if (!showModalOnBoardingCompleted || !showOnboardingRMCarousel || !completedOnboardingCourseNotif || !(completedOnboardingCourseNotif?.courseid || onboardingCourse?.courseIdMaster)) {
    return null;
  }
  
  const courseFullName = coursesMap[(completedOnboardingCourseNotif?.courseid || onboardingCourse?.courseIdMaster)]?.courseFullName;
  
  return (
    <ModalEvolution
      show={showModalOnBoardingCompleted}
      close={closeModal}
      isCloseIconSquare={true}
      modalClassName="modal-onboarding-completed"
      title={(lang?.MODAL_ON_BOARDING_COMPLETED_TITLE).toUpperCase()}
      descriptionElement={
        <div dangerouslySetInnerHTML={printHTML(lang?.ONBOARDING_MODAL_COMPLETED_DESCRIPTION?.replace("{course}", courseFullName))}></div>
      }
      mainButtonLabel={lang?.ONBOARDING_MODAL_COMPLETED_CTA}
      mainButtonAction={goToRelatedMandatoryCarousel}
      variantSessionExpired={true}
    />
  );
};

export default ModalRelatedMandatoryContent;