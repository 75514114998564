import React, { ReactElement } from "react";
import { TUseBreakpointBooleanReturn } from "@hooks/createBreakpoint";
import { TAlign } from "@components/CMSNewPages/components/Align";
import { TCMSRawLayoutVariants } from "@components/CMSNewPages/CMSTypeResponse";
import { ComponentType } from "./CMSEngine";


//<editor-fold desc="Page structure">

enum ECMSNewPageStatus {
	NOT_LOADED,
	LOADING,
	LOADED,
	LOAD_ERROR_FETCH_STRUCTURE
}

type TCMSStructure = {
	idPage: string,
	segment: string,
	pageTitle: string,
	htmlTitle: string,
	htmlDescription: string,
	pageName: string,
	rows: Array<TCMSRow>,
	dynamicHeader: TDynamicHeader,
	dynamicButtonHeader: Array<TCMSSubjectTaxonomy>,
}

type TCMSSubjectTaxonomy = {
	id: string,
	name: string,
	type: string,
	externalReference: string
}

type TCMSNewPage = {
	status: ECMSNewPageStatus
	structure: TCMSStructure | null,
	slug: string | null,
	title?: string,
	hasDynamicHeader: boolean
}

//</editor-fold>

//<editor-fold desc="Dynimc Header links">

type TRowLink = {
	linkLabel: string;  // string to show on screen
	linkString: string; // link URL refereed to a specific row (scroll to)
};

type TDynamicHeader = Array<TRowLink>;
//</editor-fold>

//<editor-fold desc="CMS Row">

enum ECMSRowStatus {
	NOT_LOADED,
	LOADING,
	LOADED,
	LOAD_ERROR,
}

enum ERowLayout {
	DEFAULT = "cms-layout--default",
	HALF = "cms-layout--half",
	ONE_THIRD = "cms-layout--onethird",
	ONE_FOURTH = "cms-layout--onefourth"
}

enum EMargin {
	MARGIN_S = 'S',
	MARGIN_M = 'M',
	MARGIN_L = 'L',
	MARGIN_XL = 'XL',
	MARGIN_X = '0'
}

const MRowLayout = new Map<string, ERowLayout>(Object.entries(
	{
		"default": ERowLayout.DEFAULT,
		"50+50": ERowLayout.HALF,
		"33+33+33": ERowLayout.ONE_THIRD,
		"25+25+25+25": ERowLayout.ONE_FOURTH,
	}
))

export type InfoCatalogRow = {
	id: string
	loaded: boolean
	isEmptyCatalog: boolean
  }

type TCMSRow = {
	id: string,
	name: string,
	layout: string,
	components?: Array<TCMSComponent>,
	anchorable: boolean
	backgroundColor: string | null,
	// content?: {},
	hasTitle?: boolean
	status?: ECMSRowStatus,
	margin?: EMargin | string | null,
	// cmsComponents?:any,
	// catalog?:any,
	infoCatalogRows?: InfoCatalogRow[]
	setInfoCatalogRows?: any
}

//</editor-fold>

//<editor-fold desc="CMS Components and shared types">

type TCMSComponentVariant =
	'no-title'        // Heading            →|
	// Insights           →|
	// TabContentModule   →|
	// ContentQueue       →|  don't render title and description
	// CardCarousel       →|   and remove padding-top
	// BrandCarousel      →|
	// AvatarCarousel     →|
	| 'title-of-row'    // Heading            → remove padding-bottom

type LayoutVariant = {
	name: ComponentType
}

type TCMSComponent = {
	isLoading: boolean,
	id?: string,
	name?: string,
	type?: string,
	backgroundColor?: string,
	layoutVariant?: Array<LayoutVariant>,
	align?: TAlign,
	idParent?: string
	cmsResponse?: any
	catalog?: any
} & Partial<React.HTMLAttributes<HTMLElement>> // all HTML attributes + react ones

type TCMSSkeleton = {
	show: boolean,
	children?: ReactElement | Array<JSX.Element> | JSX.Element,
	breakpoints?: TUseBreakpointBooleanReturn,
}


//</editor-fold>

//<editor-fold desc="Shared type between common components">

enum ECTAComponentIcon{	
	NO_ICON = "no_icon",
	ARROW_SX = "arrow_sx",
	ARROW_DX = "arrow_dx",
	//Before arrow
	ARROW_BEFORE_LEFT = "before_arrow_left",
	ARROW_BEFORE_RIGHT = "before_arrow_right",
	ARROW_BEFORE_UP = "before_arrow_up",
	ARROW_BEFORE_DOWN = "before_arrow_down",
	ARROW_BEFORE_HEAD_LEFT = "before_arrow_headleft",
	ARROW_BEFORE_HEAD_RIGHT = "before_arrow_headright",
	ARROW_BEFORE_HEAD_UP = "before_arrow_headup",
	ARROW_BEFORE_HEAD_DOWN = "before_arrow_headdown",
	//After arrow
	ARROW_AFTER_LEFT = "after_arrow_left",
	ARROW_AFTER_RIGHT = "after_arrow_right",
	ARROW_AFTER_UP = "after_arrow_up",
	ARROW_AFTER_DOWN = "after_arrow_down",
	ARROW_AFTER_HEAD_LEFT = "after_arrow_headleft",
	ARROW_AFTER_HEAD_RIGHT = "after_arrow_headright",
	ARROW_AFTER_HEAD_UP = "after_arrow_headup",
	ARROW_AFTER_HEAD_DOWN = "after_arrow_headdown",
}

type TCMSCta = {
	ctaAction: string | null,
	ctaLabel: string | null,
	layoutButton?: string | null,
	target?: "_self" | "_blank" | null,
	linkTarget?: any | null,
	onClick?: () => void
}

type TCMSLink = {
	linkLabel: string | null,
	linkString: string | null,
}

//</editor-fold>


export {
	/*types*/
	TCMSStructure, TCMSNewPage, TRowLink, TCMSRow,
	TCMSComponent, TCMSSkeleton, TCMSComponentVariant,
	TCMSCta, TCMSLink, TDynamicHeader, TCMSSubjectTaxonomy,

	/*enum*/
	ECMSNewPageStatus, ERowLayout, ECMSRowStatus, EMargin, ECTAComponentIcon,

	/*map*/
	MRowLayout
}